import { Theme } from "@mui/material";
import { Magnolia } from "domain/Magnolia";
import { getCurrentMultisiteBase } from "util/MagnoliaAppHelpers";

export function normalizeHref(href: string) {
  // remove ;jsessionid= in case it is accidently maintained in magnolia
  // so it would not appear in html to create a caching in prerender
  // remove http:// and https:// because we don´t know which one is used
  const trimmedHref = href
    .split(";jsessionid=")[0]
    .replaceAll("http://", "")
    .replaceAll("https://", "");

  const exceptions = [
    "www.mocom.eu",
    "stage.mocom.eu",
    "www.albis.com",
    "stage.albis.com",
    "www.ottokrahn.group",
    "stage.ottokrahn.group",
    "htf.krahn.eu",
    "stage.htf.krahn.eu",
    "www.krahn-ceramics.com",
    "stage.krahn-ceramics.com",
    "www.wipag.de",
    "stage.wipag.de",
    "www.wipag.com",
    "stage.wipag.com",
    "www.oknb.eu",
    "stage.oknb.eu",
    "www.krahn.eu",
    "stage.krahn.eu",
  ];

  if (exceptions.some((exception) => trimmedHref.startsWith(exception))) {
    const currentException = exceptions.find((exception) =>
      trimmedHref.startsWith(exception)
    );
    if (currentException) {
      if (process.env.NODE_ENV === "production") {
        return trimmedHref.replace(currentException, "");
      } else {
        return trimmedHref.replace(
          currentException,
          `/${getCurrentMultisiteBase()}`
        );
      }
    }
  }

  //e.g. href: `https://stage.albis.com/albis-distribution/de`
  if (process.env.NODE_ENV === "production") {
    // in production the domain decides on which Page-Tree we work
    //e.g. return `https://stage.albis.com/de`
    return trimmedHref.replace(`/${getCurrentMultisiteBase()}/`, "/");
  } else {
    // in development we have only one domain (localhost) and therefore need this distinction
    //e.g. return `http://localhost:41812/albis-distribution/de`
    return trimmedHref;
  }
}

export function getHref(link: Magnolia.Link) {
  switch (link.field) {
    case "page":
      return link.pageLink ? normalizeHref(link.pageLink) : "";
    case "external":
      return link.externalLink ?? "";
    case "download":
      return link.downloadLink ? normalizeHref(link.downloadLink) : "";
    default:
      return "";
  }
}

export function isExternalLink(link: Magnolia.Link) {
  switch (link.field) {
    case "page":
      return false;
    case "external":
      return true;
    case "download":
      return true;
    default:
      return false;
  }
}

export function getColorFromTheme(color: Magnolia.Color, theme: Theme) {
  switch (color) {
    case "common.white":
      return theme.palette.common.white;
    case "primary.dark":
      return theme.palette.primary.dark;
    case "primary.light":
      return theme.palette.primary.light;
    case "primary.main":
      return theme.palette.primary.main;
    case "text.primary":
      return theme.palette.text.primary;
    case "text.secondary":
      return theme.palette.text.secondary;
    case "secondary.main":
      return theme.palette.secondary.main;
    case "secondary.light":
      return theme.palette.secondary.light;
    case "info.main":
      return theme.palette.info.main;
    case "warning.main":
      return theme.palette.warning.main;
    case "success.main":
      return theme.palette.success.main;
    case "lightgray":
      return "#E8EBED";
    case "jobpages.primary":
      return theme.palette.jobpages?.primary ?? theme.palette.primary.main;
    case "jobpages.secondary":
      return theme.palette.jobpages?.secondary ?? theme.palette.primary.main;
    case "jobpages.tertiary":
      return theme.palette.jobpages?.tertiary ?? theme.palette.primary.main;
    default:
      return theme.palette.primary.main;
  }
}

/**
 * Magnolia produces crazy structures e.g. when using a multifield.
 * Instead of using an array, the object contains multiple enumerated keys as
 *
 * { multifieldProp0: valueA, multifieldProp1: valueB}
 *
 * This function creates an array, when given such object,
 * the magnolia multifield name, and the @nodes property
 * contained as metadata from Magnolia
 *
 * => [valueA, valueB]
 *
 * @param multifieldKey
 * @param jcrData
 * @param nodes
 */
export function convertMultifieldStructure<ResultType = Magnolia.ContentNode>(
  // e.g. multiFieldKey = "abc"
  multifieldKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jcrData: any,
  nodes: string[]
) {
  const values: ResultType[] = [];
  nodes
    .filter((nodeKey) => {
      // e.g. `nodeKey = "abc0"`
      return (
        // make sure the wanted key is included
        nodeKey.includes(multifieldKey) &&
        // and make sure it's not only included but the whole key (the rest should be a number only)
        // this prevents the logic from matching `nodeKey = "abcdef0"`
        !isNaN(Number(nodeKey.replace(multifieldKey, "")))
      );
    })
    .forEach((nodeKey) => {
      if (jcrData[nodeKey]) {
        values.push(jcrData[nodeKey] as ResultType);
      } else {
        // this should not happen
      }
    });

  return values;
}

export function formatFileSize(number: number) {
  const bytesToKB = number / 1024;
  return new Intl.NumberFormat("de-DE", {
    maximumFractionDigits: 0,
  }).format(bytesToKB);
}
