export class CustomError extends Error {
  constructor(message: string, name: string = "CustomError") {
    super(message);
    this.name = name;
  }
}

export class ServerError extends CustomError {
  constructor(message: string, name: string = "ServerError") {
    super(message, name);
  }
}

export class ClientError extends CustomError {
  constructor(message: string, name: string = "ClientError") {
    super(message, name);
  }
}

export class UnauthorizedError extends ClientError {
  constructor(message: string, name: string = "UnauthorizedError") {
    super(message, name);
  }
}

export class BadRequestError extends ClientError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, data: any, name: string = "BadRequestError") {
    super(message, name);

    this.data = data;
  }
}

export class ForbiddenError extends ClientError {
  constructor(message: string, name: string = "ForbiddenError") {
    super(message, name);
  }
}

export class NotFoundError extends ClientError {
  constructor(message: string, name: string = "NotFoundError") {
    super(message, name);
  }
}

export class ConflictError extends ClientError {
  constructor(message: string, name: string = "ConflictError") {
    super(message, name);
  }
}

export class InternalError extends CustomError {
  constructor(message: string, name: string = "InternalError") {
    super(message, name);
  }
}

export class PayLoadTooLargeError extends CustomError {
  constructor(message: string, name: string = "PayLoadTooLargeError") {
    super(message, name);
  }
}
