import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

export type LoginCredentials = {
  email: string;
  customerNo: string;
  redirectUrl: string;
};

export type SessionToken = {
  token: string;
  expires: number;
  customerNo: string;
  orderNo: string;
};

export type VerifyBody = {
  token: string;
};

const basePath = getAPIBase() + "/.rest/api";
const restAuth = basePath + "/auth";
const restToken = restAuth + "/token";

export default class LoginService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  login = async (loginBody: LoginCredentials) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const { status } = await httpClient.post(`${restAuth}`, loginBody);
    return status;
  };

  verify = async (verifyBody: VerifyBody) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    try {
      const { data: sessionToken } = await httpClient.post<SessionToken>(
        `${restToken}`,
        verifyBody
      );
      return sessionToken;
    } catch (err) {
      console.log("Error: Login verify request failed", err);
    }
  };
}
