import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

export type ContactCountry = { code: string; name: string };
export type Contact = {
  contactFirstname: string;
  contactLastname: string;
  contactMail: string;
  contactPhone: string;
  countryCode: string;
  countryName: string;
  locationMail: string;
  locationPhone: string;
  office: string;
  zipcode: string;
};

export type ContactRequest = {
  countryCode: string;
  zipcode: string;
  name: string;
  email: string;
  callbackRequested: boolean;
  phone: string;
  reason: string;
  privacyAgreementAccepted: boolean;
  target?: "PROTOFY_DEV";
};

const basePath = getAPIBase() + "/.rest/api";
const restContactFinderBase = basePath + "/countries";
const restContactRequest = basePath + "/contact/request";
export const NOT_FOUND = "NOT_FOUND";

export default class ContactFinderService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  getContact = async ({
    countryCode,
    languageCode,
    zipCode,
  }: {
    countryCode: string;
    languageCode: string;
    zipCode: string;
  }) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    try {
      return await httpClient.get<Contact>(
        `${restContactFinderBase}/${countryCode}/contact?zipCode=${zipCode}&language=${languageCode}`,
      );
    } catch (err) {
      console.log("Error: getContact request failed", err);
      return NOT_FOUND;
    }
  };

  getCountries = async (languageCode: string) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    return await httpClient.get<ContactCountry[]>(
      `${restContactFinderBase}?language=${languageCode}`
    );
  };

  sendContactRequest = async (
    requestBody: ContactRequest,
    target?: "PROTOFY_DEV"
  ) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    return await httpClient.post<ContactRequest>(
      target ? `${restContactRequest}?target=${target}` : restContactRequest,
      requestBody,
      { headers: { "Content-Type": "application/json" } }
    );
  };
}
