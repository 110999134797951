/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { LoggerService } from '@magnolia/template-annotations';
import constants from './constants';

function componentHelper() {
  return {
    getRenderedComponent, getComponentProperties, addComment, classnames, removeCommentElement
  };

  function getRenderedComponent(componentContent, componentMappings, index = 0) {
    if (!componentContent || !componentMappings) {
      return React.createElement('div');
    }

    const componentClass = componentMappings[componentContent[constants.TEMPLATE_ID_PROP]];
    if (!componentClass) {
      LoggerService.error(`Component with ID ${componentContent[constants.TEMPLATE_ID_PROP]} is not mapped.`);
      return React.createElement('div');
    }

    return React.createElement(componentClass, getComponentProperties(componentContent, index));
  }

  function getComponentProperties(componentContent, index) {
    if (!componentContent || typeof componentContent !== 'object') {
      return {};
    }
    const props = {};
    const metadata = { '@index': index };
    Object.keys(componentContent).forEach(key => {
      if (key.startsWith('@') || key.startsWith('mgnl:') || key.startsWith('jcr:')) {
        metadata[key] = componentContent[key];
      } else {
        props[key] = componentContent[key];
      }
    });
    props.metadata = metadata;
    return props;
  }

  function addComment(element, openComment, closeComment) {
    if (typeof document === 'undefined' || !element) {
      return;
    }
    if (openComment) {
      const openCommentElement = document.createComment(openComment);
      element.insertBefore(openCommentElement, element.firstChild);
    }
    if (closeComment) {
      const closeCommentElement = document.createComment(closeComment);
      element.appendChild(closeCommentElement);
    }
  }

  function classnames(...arg) {
    const classes = [];
    arg.forEach(item => {
      if (item == null) {
        return;
      }
      const itemType = typeof item;
      if (itemType === 'string' || itemType === 'number') {
        classes.push(item);
      } else if (Array.isArray(item) && item.length) {
        classes.push(classnames(...item));
      } else if (itemType === 'object') {
        Object.keys(item).forEach(key => {
          if (item[key]) {
            classes.push(key);
          }
        });
      }
    });
    return classes.join(' ');
  }

  function removeCommentElement(element) {
    if (element && element.nodeType === 8) {
      element.remove();
    }
  }
}

export default componentHelper();
