import { Magnolia } from "domain/Magnolia";
import { HTTPClientService } from "services/HTTPClient";
import {
  getAPIBase,
  getCurrentLanguage,
  getCurrentMultisiteBase,
} from "util/MagnoliaAppHelpers";

const basePath = getAPIBase();
const restJCRBase = basePath + process.env.REACT_APP_MGNL_API_PAGES;
const restJCRMetaBase = basePath + process.env.REACT_APP_MGNL_API_PAGES_META;
const restContactBase = basePath + process.env.REACT_APP_MGNL_API_CONTACT;
const restTemplatesBase = basePath + process.env.REACT_APP_MGNL_API_TEMPLATES;
const restNavigationBase = basePath + process.env.REACT_APP_MGNL_API_NAV;

// avoids unneccessary calls to magnolia that will fail anyway because of app structure of components
// see App.tsx for more
const exeptionPaths = [
  RegExp(/product-search\/\w+/),
  RegExp(/products-overview\/\w+/),
  RegExp(/products-brands\/\w+/),
  RegExp(/press~\/\w+/),
  RegExp(/blog~\/\w+/),
  RegExp(/htf-products\/\w+/),
  RegExp(/career\/graduate\/\w+/),
  RegExp(/career\/student\/\w+/),
  RegExp(/career\/professional\/\w+/),
  RegExp(/career\/job-starter\/\w+/),
  RegExp(/orders\/\w+/),
  RegExp(/playground\/\w+/),
  RegExp(/industries\/\w+/),
  RegExp(/career\/jobs\/\w+/),
  RegExp(/career\/job\/\w+/),
  RegExp(/career\/job-cf\/\w+/),
  RegExp(/about\/career\/job\/\w+/),
  RegExp(/job\/\w+/),
];

declare global {
  interface Window {
    CACHE: {
      pageData: {
        [pagePath: string]: Magnolia.PageMetaData | undefined;
      };
      pageMetaData: {
        [pagePath: string]: Magnolia.PageMetaData | false | undefined;
      };
      tplData: {
        [templateId: string]: { [key: string]: Magnolia.Template } | undefined;
      };
      contactData: {
        [path: string]: Magnolia.Contact | undefined;
      };
      navData: Magnolia.NavigationData | undefined;
      topLevelArea: { [area: string]: Magnolia.Area };
    };
  }
}

window.CACHE = {
  pageData: {},
  pageMetaData: {},
  tplData: {},
  topLevelArea: {},
  contactData: {},
  navData: undefined,
};

export default class ContentService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  normalizePagePath(pagePath: string) {
    const multisiteBase = getCurrentMultisiteBase();
    const multisiteBasePath = `/${multisiteBase}`;
    if (pagePath.startsWith(multisiteBasePath)) {
      if (pagePath.endsWith("/")) {
        return `${multisiteBase}${pagePath
          .slice(0, pagePath.length - 1)
          .replace(multisiteBasePath, "")}`;
      } else {
        return `${multisiteBase}${pagePath.replace(multisiteBasePath, "")}`;
      }
    } else {
      if (pagePath.endsWith("/")) {
        return `${multisiteBase}${pagePath.slice(0, pagePath.length - 1)}`;
      } else {
        return `${multisiteBase}${pagePath}`;
      }
    }
  }

  // eslint-disable-next-line
  getTopLevelArea = async <T extends any>(
    area: string,
    force = false
  ): Promise<Magnolia.Area & T> => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    if (window.CACHE.topLevelArea[area] && !force) {
      return Promise.resolve(
        window.CACHE.topLevelArea[area] as Magnolia.Area & T
      );
    }
    const { data } = await httpClient.get<Magnolia.Area & T>(
      `${restJCRBase}/${getCurrentMultisiteBase()}/${getCurrentLanguage()}/${area}`
    );
    window.CACHE.topLevelArea[area] = data;
    return data;
  };

  gatherAllPageData = async (pagePath: string, force = false) => {
    return await Promise.all([
      this.loadPage(pagePath, force),
      this.loadNavigationData(force),
    ]);
  };

  private loadPage = async (
    pagePath: string,
    force = false
  ): Promise<Magnolia.PageMetaData> => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    if (window.CACHE.pageData[pagePath] && !force) {
      return Promise.resolve(window.CACHE.pageData[pagePath]!);
    }

    let fullContentPath = `${restJCRBase}/${this.normalizePagePath(pagePath)}`;
    const { data } = await httpClient.get<Magnolia.PageMetaData>(
      fullContentPath
    );
    window.CACHE.pageData[pagePath] = data;
    return data;
  };

  loadPageMetaData = async (
    pagePath: string,
    force = false
  ): Promise<Magnolia.PageMetaData | false> => {
    if (window.CACHE.pageData[pagePath] !== undefined && !force) {
      return Promise.resolve(window.CACHE.pageMetaData[pagePath]!);
    }
    // avoids unneccessary calls to magnolia that will fail anyway because of app structure of components
    if (
      exeptionPaths.some((path) => path.test(pagePath)) &&
      pagePath !== "/industries/scientific-instruments" &&
      pagePath !== "/industries/krahn-specialty-fluids"
    ) {
      return false;
    }
    const httpClient = this.httpClientService.getAlbisHttpClient();
    let fullContentPath = `${restJCRMetaBase}/${this.normalizePagePath(
      pagePath
    )}`;

    try {
      const { data } = await httpClient.get<Magnolia.PageMetaData>(
        fullContentPath
      );
      window.CACHE.pageMetaData[pagePath] = data;
      return data;
    } catch (e) {
      window.CACHE.pageMetaData[pagePath] = false;
      return false;
    }
  };

  loadTemplateDefinitions = async (
    templateId: string,
    force = false
  ): Promise<{ [key: string]: Magnolia.Template }> => {
    if (window.CACHE.tplData[templateId] && !force) {
      return Promise.resolve(window.CACHE.tplData[templateId]!);
    }
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get<{ [key: string]: Magnolia.Template }>(
      restTemplatesBase + "/" + templateId
    );
    window.CACHE.tplData[templateId] = data;
    return data;
  };

  getNavigationData = () => window.CACHE.navData;
  private loadNavigationData = async (
    force = false
  ): Promise<Magnolia.NavigationData> => {
    if (window.CACHE.navData && !force) {
      return Promise.resolve(window.CACHE.navData!);
    }
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get<Magnolia.NavigationData>(
      `${restNavigationBase}/${getCurrentMultisiteBase()}`
    );
    window.CACHE.navData = data;
    return window.CACHE.navData;
  };

  loadContact = async (
    path: string,
    force = false
  ): Promise<Magnolia.Contact> => {
    if (window.CACHE.contactData[path] && !force) {
      return Promise.resolve(window.CACHE.contactData[path]!);
    }
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get<Magnolia.Contact>(
      `${restContactBase}${path}`
    );
    window.CACHE.contactData[path] = data;
    return data;
  };
}
