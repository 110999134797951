import { atom, selector } from "recoil";
import { de } from "const/lang/de";
import { en } from "const/lang/en";
import Services from "services/Services";
import { getCurrentMultisiteBase, getLanguages } from "util/MagnoliaAppHelpers";
import { pagePathState } from "./magnolia";
import { PERSIST_KEY_LANGUAGE } from "./persist";

export const languageState = atom({
  key: "languageState",
  default: localStorage.getItem(PERSIST_KEY_LANGUAGE) || "de",
});

/**
 * Selector for the urls of the content in all languages
 */
export const contentVariants = selector({
  key: "contentVariants",
  get: async ({ get }) => {
    // all languages that we have => ideally coming from magnolia
    let LANGUAGES = getLanguages();
    let currentLanguage = get(languageState);
    let currentPagePath = get(pagePathState);
    const currentMultisite = getCurrentMultisiteBase();
    let contentVariants: { [key: string]: string } = {};

    // let's check with Magnolia if the page is available in other languages
    for (var i = 0; i < LANGUAGES.length; i++) {
      let languageToCheck = LANGUAGES[i];

      // KRAHN Chemie has no German version, this request would result in an error 404
      // TODO: remove this when the site is available in German
      if (currentMultisite === 'krahn-chemie' && languageToCheck === 'de') {
        continue
      }

      if (languageToCheck === currentLanguage) {
        contentVariants[currentLanguage] = currentPagePath;
      } else {
        let pathToCheck = currentPagePath.replace(
          `/${currentLanguage}`,
          `/${languageToCheck}`
        );
        if (pathToCheck.includes("/htf-products/")) {
          contentVariants[languageToCheck] = pathToCheck;
          continue;
          // TODO
          // WHATS HAPPEN HERE?????
          // forces a page reload!
        }

        try {
          // we make a call to the meta data, if successful => it's available

          const data = await Services.content.loadPageMetaData(
            pathToCheck.includes("/press~") || pathToCheck.includes("/blog~")
              ? exception(pathToCheck)
              : pathToCheck
          );
          if (data === false) {
            throw new Error("notExists");
          }
          contentVariants[languageToCheck] = pathToCheck;
        } catch (e) {
          // if not => not available, landing page is the preferred destination then
          contentVariants[languageToCheck] = `/${languageToCheck}`;
        }
      }
    }

    return contentVariants;
  },
});

function exception(pagePath: string) {
  const productSearchIdentifier = "/press";
  const indexOfProductSearch = pagePath.indexOf(productSearchIdentifier);

  return pagePath.substr(
    0,
    indexOfProductSearch + productSearchIdentifier.length
  );
}

/**
 * Selector for the translation file corresponding to the current language
 */
export const i18n = selector({
  key: "i18n",
  get: ({ get }) => {
    const currentLanguage = get(languageState);

    switch (currentLanguage) {
      case "de":
        return de;
      default:
        return en;
    }
  },
});

export const getTranslationByKey = selector({
  key: "getTranslationByKey",
  get: () => (key?: string) => {
    switch (key) {
      case "en":
        return en;
      default:
        return de;
    }
  },
});
