import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "../util/MagnoliaAppHelpers";
import { Magnolia } from "domain/Magnolia";

const basePath = getAPIBase();
const restBaseProducts =
  basePath + process.env.REACT_APP_MGNL_API_PRODUCTS_PRODUCTS;
const restBaseDistributors =
  basePath + process.env.REACT_APP_MGNL_API_PRODUCTS_DISTRIBUTORS;
const restBaseBrands =
  basePath + process.env.REACT_APP_MGNL_API_PRODUCTS_BRANDS;

export default class ContentService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  loadDistributor = async (path: string) => {
    // e.g. path: "/de/mocom"
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const { data } = await httpClient.get<Magnolia.Distributor>(
      `${restBaseDistributors}${path}`
    );
    return data;
  };

  // currently not used anymore
  loadBrand = async (path: string) => {
    // e.g. path: "/de/albis-technical-compounds/alcom"
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get<Magnolia.Brand>(
      `${restBaseBrands}${path}`
    );
    return data;
  };

  // currently not used anymore
  loadProduct = async (path: string) => {
    // e.g. path: "/de/albis-technical-compounds/alcom/alcom-cfx"
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const { data } = await httpClient.get<Magnolia.Product>(
      `${restBaseProducts}${path}`
    );
    return data;
  };
}
