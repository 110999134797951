import { EditablePage } from "@magnolia/react-editor";
import { EditorContextHelper } from "@magnolia/template-annotations";
import { Box } from "@mui/material";
import React, { Suspense } from "react";
import LoadingIndicator from "components/common/Basics/LoadingIndicator";
import { Magnolia } from "domain/Magnolia";
import Services from "services/Services";
import config from "../magnolia.config";
import Error from "../pages/Error";

interface PageLoaderProps {
  routingExceptions: {
    [key: string]: (pagePath: string) => string;
  };
}
interface PageLoaderState {
  pathname: string;
  // customRoutingProvider?: string;
  loading: boolean;
  // eslint-disable-next-line
  content: any;
  error?: string;
  templateDefinitions: { [key: string]: Magnolia.Template } | null;
}
class PageLoader extends React.Component<PageLoaderProps, PageLoaderState> {
  constructor(props: PageLoaderProps) {
    super(props);
    this.state = {
      pathname: "",
      // customRoutingProvider: undefined,
      content: {},
      loading: true,
      templateDefinitions: {} as { [key: string]: Magnolia.Template },
      error: undefined,
    };
  }

  getPagePath = () => {
    let route = window.location.pathname;

    const exceptionPath = Object.keys(
      this.props.routingExceptions
    ).find((subPath) => window.location.pathname.includes(subPath));

    if (exceptionPath) {
      route = this.props.routingExceptions[exceptionPath](route);
    }

    if (!route.endsWith("/")) {
      route += "/";
    }

    return route;
  };

  loadPage = async () => {
    const pathname = this.getPagePath();

    // Bail out if already loaded content.
    if (this.state.pathname === pathname) return;

    this.setState({
      loading: true,
      pathname,
    });

    try {
      const [pageData] = await Services.content.gatherAllPageData(
        pathname,
        EditorContextHelper.inEditor()
      );

      let templateDefinitions = null;
      if (EditorContextHelper.inEditor()) {
        templateDefinitions = await Services.content.loadTemplateDefinitions(
          pageData["mgnl:template"]
        );
      }

      this.setState({
        loading: false,
        content: pageData,
        templateDefinitions,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e.message || "error",
      });
    }
  };

  inEditorPreview() {
    const url = window.location.href;
    const inPreview = url.indexOf("mgnlPreview=true") > 0;
    return EditorContextHelper.inEditor() && inPreview;
  }

  componentDidMount() {
    this.loadPage();
    if (EditorContextHelper.inEditor()) {
      if (!this.inEditorPreview()) {
        EditorContextHelper.refresh();
      }
    }
  }

  componentDidUpdate() {
    if (window.location.href !== this.state.pathname) {
      this.loadPage();
      if (EditorContextHelper.inEditor()) {
        if (!this.inEditorPreview()) {
          EditorContextHelper.refresh();
        }
      }
    }
  }

  renderLoadingIndicator = () => {
    return (
      <Box
        height={"100vh"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <LoadingIndicator />
      </Box>
    );
  };

  render() {
    if (this.state.error) {
      return <Error error={JSON.stringify(this.state.error)} />;
    } else if (this.state.content?.error) {
      return <Error error={JSON.stringify(this.state.content.error)} />;
    } else {
      return (
        <Suspense fallback={this.renderLoadingIndicator()}>
          {this.state.loading ? (
            this.renderLoadingIndicator()
          ) : (
            <EditablePage
              templateDefinitions={this.state.templateDefinitions || {}}
              content={this.state.content}
              config={config}
            />
          )}
        </Suspense>
      );
    }
  }
}

export default PageLoader;
