import { Magnolia } from "domain/Magnolia";

/**
 * Is running on Magnolia server, either in editor or on public instance.
 */
export function onMagnolia() {
  return process.env.REACT_APP_MGNL_IS_PREVIEW;
}

export function getAPIBase() {
  let M;
  if (Boolean(process.env.REACT_APP_MGNL_IS_PREVIEW)) {
    M = process.env.REACT_APP_MGNL_BASE_AUTHOR;
  } else {
    M = process.env.REACT_APP_MGNL_BASE_PUBLIC;
  }
  let API_BASE = process.env.REACT_APP_MGNL_HOST! + M;
  return API_BASE;
}

export function getLanguages() {
  return process.env.REACT_APP_MGNL_LANGUAGES!.split(" ");
}

export function removeCurrentLanguage(string: string, currentLanguage: string) {
  return string.replace(new RegExp("/" + currentLanguage + "($|/)"), "/");
}

export function getCurrentLanguage() {
  const languages = getLanguages();

  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];

    if (new RegExp("/" + language + "($|/)").test(window.location.pathname)) {
      return language;
    }
  }

  return languages[0];
}

export const getBrandFromLocation = (): Magnolia.MultisiteId => {
  if (window.location.pathname.startsWith("/albis-distribution")) {
    return "albis";
  } else if (window.location.pathname.startsWith("/mocom")) {
    return "mocom";
  } else if (window.location.pathname.startsWith("/krahn-ceramics")) {
    return "krahnCeramics";
  } else if (window.location.pathname.startsWith("/otto-krahn-group")) {
    return "ottoKrahnGroup";
  } else if (window.location.pathname.startsWith("/htf")) {
    return "htf";
  } else if (window.location.pathname.startsWith("/wipag")) {
    return "wipag";
  } else if (window.location.pathname.startsWith("/plgrd")) {
    return "plgrd";
  } else if (window.location.pathname.startsWith("/krahn-chemie")) {
    return "krahnChemie";
  } else if (window.location.pathname.startsWith("/otto-krahn-new-business")) {
    return "okNewBusiness";
  } else if (window.location.origin.includes("mocom.eu")) {
    return "mocom";
  } else if (window.location.origin.includes("albis.com")) {
    return "albis";
  } else if (window.location.origin.includes("krahn-ceramics.com")) {
    return "krahnCeramics";
  } else if (window.location.origin.includes("ottokrahn.group")) {
    return "ottoKrahnGroup";
  } else if (window.location.origin.includes("htf.krahn.eu")) {
    return "htf";
  } else if (window.location.origin.includes("wipag.de")) {
    return "wipag";
  } else if (window.location.origin.includes("wipag.com")) {
    return "wipag";
  } else if (window.location.origin.includes("krahn.eu")) {
    return "krahnChemie";
  } else if (window.location.origin.includes("oknb.eu")) {
    return "okNewBusiness";
  }
  return "albis";
};

export const getCurrentMultisiteBase = () => {
  const brand = getBrandFromLocation();
  switch (brand) {
    case "albis":
      return "albis-distribution";
    case "mocom":
      return "mocom";
    case "krahnCeramics":
      return "krahn-ceramics";
    case "ottoKrahnGroup":
      return "otto-krahn-group";
    case "htf":
      return "htf";
    case "plgrd":
      return "plgrd";
    case "wipag":
      return "wipag";
    case "krahnChemie":
      return "krahn-chemie";
    case "okNewBusiness":
      return "otto-krahn-new-business";
    default:
      return "albis-distribution";
  }
};
