import React, { lazy } from "react";
import { Redirect } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { normalizeHref } from "domain/util/Magnolia";
import { recentExternalLink } from "state/recentExternalLink";

const LoadingIndicator = lazy(
  () => import("components/common/Basics/LoadingIndicator")
);

interface MagnoliaRedirectPageProps {
  linkTypepage: string;
  linkType: string;
  linkTypeexternal: string;
}
const RedirectPage = ({
  linkTypepage: link,
  linkType,
  linkTypeexternal,
}: MagnoliaRedirectPageProps) => {
  const recentExternalOpened = useRecoilValue(recentExternalLink);
  const setRecentExternalOpened = useSetRecoilState(recentExternalLink);

  if (linkType === "external") {
    let windowOpenReference = null;
    if (recentExternalOpened !== linkTypeexternal) {
      windowOpenReference = window.open(linkTypeexternal, "_blank");
      if (windowOpenReference !== null) {
        window.history.back();
      }
      setRecentExternalOpened(linkTypeexternal);
    }

    return <LoadingIndicator />;
  } else {
    const relativeLink = normalizeHref(link);

    return <Redirect to={relativeLink} />;
  }
};

export default RedirectPage;
