import EditorContextHelper from './EditorContextHelper';
const listeners = {};

export const onMessage = (action, callback) => {
  if (!action || typeof callback !== 'function') {
    return;
  }
  const actionLowerCase = action.toLowerCase();
  listeners[actionLowerCase] = listeners[actionLowerCase] || [];
  listeners[actionLowerCase].push(callback);
};

export function getBaseServerUrl(serverUrl) {
  const url = new URL(serverUrl);
  return `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}${url.pathname.substring(0, url.pathname.indexOf('.magnolia'))}`;
}

function getActionListeners(action) {
  if (!action) {
    return [];
  }
  return listeners[action.toLowerCase()] || [];
}

function registerPageEditor(callback, serverUrl) {
  const id = 'mgnlPageEditorBridge';
  let bridgeScript = document.getElementById(id);
  if (bridgeScript || !serverUrl) {
    if (typeof callback === 'function') {
      callback();
    }
    return;
  }
  const hostUrl = getBaseServerUrl(serverUrl);
  const firstScript = document.getElementsByTagName('script')[0];
  bridgeScript = document.createElement('script');
  bridgeScript.id = id;
  bridgeScript.src = `${hostUrl}VAADIN/widgetsets/info.magnolia.PageEditor/info.magnolia.PageEditor.nocache.js`;
  firstScript.parentNode.insertBefore(bridgeScript, firstScript);
  bridgeScript.onload = () => {
    // Wait for the page editor is ready and call necessary functions
    setTimeout(function waitAndCall() {
      if (EditorContextHelper.isPageEditorReady()) {
        EditorContextHelper.onFrameReady();
        EditorContextHelper.refresh();
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        setTimeout(waitAndCall, 100);
      }
    }, 50);
  };

  const style = document.createElement('link');
  style.href = `${hostUrl}VAADIN/themes/pages-app/page-editor.css`;
  style.type = 'text/css';
  style.rel = 'stylesheet';
  document.head.insertBefore(style, document.head.firstChild);
}

export const requestServerUrl = () => {
  const notifyMessage = {
    element: {
      dialog: '',
      path: '/',
      workspace: 'website'
    },
    action: 'initialize',
    elementType: 'page'
  };
  window.parent.postMessage(JSON.stringify(notifyMessage), '*');
};

export const init = (callback) => {
  if (EditorContextHelper.isSameOrigin()) {
    return;
  }
  window.addEventListener('message', (event) => {
    if (!event.data || !(typeof event.data === 'string' && event.data.startsWith('{'))) return;
    const message = JSON.parse(event.data);
    const actionListeners = getActionListeners(message.action);
    actionListeners.forEach(listener => {
      try {
        listener(message);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  });
  onMessage('updateState', (message) => {
    const serverUrl = message.serverUrl;
    EditorContextHelper.setServerUrl(serverUrl);
    registerPageEditor(callback, serverUrl);
  });
  requestServerUrl();
};

export default {
  init,
  onMessage,
  requestServerUrl
};
