import getMagnoliaContext from './GetMagnoliaContext';

export const isSameOrigin = () => {
  try {
    const { href } = window.parent.location;
    return href != null;
  } catch (error) {
    return false;
  }
};

function getWindowInstance() {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return isSameOrigin() ? window.parent : window;
}

const context = {
  serverUrl: isSameOrigin() && typeof window !== 'undefined' ? getWindowInstance().location.href : ''
};

const sleep = (ms) => new Promise(r => {
  setTimeout(r, ms);
});
const waitFor = async (f) => {
  // eslint-disable-next-line no-await-in-loop
  while (!f()) await sleep(200);
  return f();
};

export const inIframe = () => {
  if (typeof window !== 'undefined') {
    return window !== window.parent;
  }
  if (typeof global === 'object') {
    if (process.env.NODE_ENV !== 'production' && global.mgnlInPageEditor === undefined) {
      // eslint-disable-next-line no-console
      console.log('For SSR, please set \'global.mgnlInPageEditor\' to true when you need to render edit bars, and false when not.');
    }
    return Boolean(global.mgnlInPageEditor);
  }
  return false;
};

export const inPreviewAsVisitor = () => {
  if (typeof window === 'undefined') {
    return typeof global === 'object' ? global.mgnlPreviewAsVisitor : false;
  }
  return inIframe() && new URLSearchParams(window.location.search).get('mgnlPreviewAsVisitor') === 'true';
};

/**
 * @deprecated Since version 1.1.0. Use inEditorAsync instead.
 */
export const inEditor = () => {
  return inIframe() && context.serverUrl.endsWith(':edit');
};

export const inEditorAsync = async () => {
  if (inIframe() && await waitFor(() => context.serverUrl.length > 0)) {
    return context.serverUrl.endsWith(':edit');
  }
  return false;
};

/**
 * @deprecated Since version 1.1.0. Use inEditorAsync instead.
 */
export const inEditorPreview = () => {
  return inIframe() && context.serverUrl.endsWith(':view');
};

/**
 * @deprecated Since version 1.1.0. Use inEditorAsync instead.
 */
export const isRenderingAnnotation = () => {
  return inIframe() && (inEditor() || !context.serverUrl);
};

let refreshTimeoutID = null;
export const refresh = (delayInMilliseconds) => {
  if (refreshTimeoutID) {
    clearTimeout(refreshTimeoutID);
  }
  refreshTimeoutID = setTimeout(callMgnlRefresh, delayInMilliseconds);
};

const callMgnlRefresh = () => {
  const windowInstance = getWindowInstance();
  if (inIframe() && windowInstance.mgnlRefresh) {
    if (document.documentElement.innerHTML.indexOf('cms:page content=') > -1) {
      windowInstance.mgnlRefresh();
    } else {
      refresh(200);
    }
  }
};

export const isPageEditorReady = () => {
  const windowInstance = getWindowInstance();
  return windowInstance.mgnlFrameReady !== undefined;
};

export const onFrameReady = () => {
  const windowInstance = getWindowInstance();
  if (inIframe() && windowInstance.mgnlFrameReady) {
    windowInstance.mgnlFrameReady();
  }
};

export const setServerUrl = (url) => {
  context.serverUrl = url;
};

export default {
  inEditor,
  inEditorAsync,
  inEditorPreview,
  inIframe,
  isRenderingAnnotation,
  isSameOrigin,
  refresh,
  setServerUrl,
  isPageEditorReady,
  onFrameReady,
  inPreviewAsVisitor,
  getMagnoliaContext
};
