import "navigation-polyfill";
import PrerenderingHelper from "protofy-prerender";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import smoothscroll from "smoothscroll-polyfill";
import "swiper/swiper.scss";
import App from "./App";
import "./fonts.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// kick off the polyfill!
smoothscroll.polyfill();

const AppWrapper = () => {
  useEffect(() => {
    PrerenderingHelper.init();
  });

  return (
    <React.StrictMode>
      <HelmetProvider>
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </HelmetProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
