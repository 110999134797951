import { JobCompany, OnlyfyJob, Seniority } from "domain/jobpages/OnlyfyJob";

const getJobCompany = (company: string): JobCompany | undefined => {
  if (company.includes("KRAHN")) {
    return JobCompany.KRAHN;
  }
};

const getDepartmentFromSpreadsheet = (department: string): number => {
  switch (department) {
    case "Administration, Sachbearbeitung und Verwaltung":
      return 29;
    case "Beratung / Consulting":
      return 26;
    case "Customer Service und Kundenbetreuung":
      return 2;
    case "Einkauf, Materialwirtschaft und Logistik":
      return 11;
    case "Forschung, Lehre und Entwicklung":
      return 20;
    case "Grafik, Design und Architektur":
      return 3;
    case "Ingenierwesen und technische Berufe":
      return 30;
    case "IT und Softwareentwicklung":
      return 9;
    case "Management und Unternehmensentwicklung":
      return 6;
    case "Marketing und Werbung":
      return 12;
    case "Personalwesen und HR":
      return 31;
    case "PR, Öffentlichkeitsarbeit und Journalismus":
      return 17;
    case "Produktion und Handwerk":
      return 16;
    case "Produktmanagement":
      return 32;
    case "Projektmanagement":
      return 15;
    case "Prozessplanung und Qualitätsmanagement":
      return 19;
    case "Recht":
      return 10;
    case "Sonstige Tätigkeitsfelder":
      return 13;
    case "Vertrieb und Handel":
      return 4;
    case "Finanzen, Rechnungswesen und Controlling":
    default:
      return 1;
  }
};

export const getOnlyfyJobFromSpreadsheet = (
  rows: (string | null)[]
): OnlyfyJob | undefined => {
  // check if all fields are filled with information
  // if not return undefined
  if (rows.some((row) => row === "" || row === null) || rows.length < 9) {
    return undefined;
  }

  // if yes continue mapping
  const job = {
    title: rows[1],
    department: getDepartmentFromSpreadsheet(rows[5]!),
    seniority: rows[2] as Seniority,
    positionType: rows[7],
    contractType: rows[8],
    company: getJobCompany(rows[0]!),
    jobContents: [],
    shortHandle: rows[4],
    startOfWork: new Date(),
    location: rows[6]!.split(",")[1].trim(),
  } as OnlyfyJob;

  return job;
};
