import {
  NeedHelpRequest,
  ReorderProductRequest,
  StartConversationRequest,
} from "domain/client-area/Contact";
import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

const basePath = getAPIBase() + "/.rest/api";
const restStartConversation = basePath + "/contact/start-conversation";
const restNeedHelp = basePath + "/contact/need-help";
const restReorderProduct = basePath + "/contact/reorder-product";

export default class ClientAreaContactService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  startConversation = async (requestData: StartConversationRequest) => {
    const httpClient = this.httpClientService.getMultipartClient();

    return await httpClient.post(`${restStartConversation}`, requestData);
  };

  needHelp = async (requestData: NeedHelpRequest) => {
    const httpClient = this.httpClientService.getMultipartClient();

    return await httpClient.post(`${restNeedHelp}`, requestData);
  };

  reorderProduct = async (requestData: ReorderProductRequest) => {
    const httpClient = this.httpClientService.getMultipartClient();

    return await httpClient.post(`${restReorderProduct}`, requestData);
  };
}
