import { languageState } from "state/language";
import { useRecoilValue } from "recoil";

const Error = ({ error }: { error: String }) => {
  const currentLanguage = useRecoilValue(languageState);
  window.location.href = `/${currentLanguage}/404`;
  return null;
};

export default Error;
