import { SESSION_TOKEN } from "const/clientArea";
import { OrderFilters } from "domain/client-area/Filter";
import { OrderHistory } from "domain/client-area/Order";
import { OrderDetails } from "domain/client-area/OrderDetails";
import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

const basePath = getAPIBase() + "/.rest/api";
const restCustomer = basePath + "/customer";
const restOrderHistory = "/order-history";
const restOrderDetails = basePath + "/deliveries";
export default class OrderService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  getOrderHistory = async (customerNo: string, filter: OrderFilters) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const sessionTokenStr = localStorage.getItem(SESSION_TOKEN);
    if (sessionTokenStr !== null) {
      const { token } = JSON.parse(sessionTokenStr);

      return await httpClient
        .get<OrderHistory>(
          `${restCustomer}/${customerNo}${restOrderHistory}?${getFilterParams(
            filter
          )}`,
          {
            headers: {
              token: token,
            },
          }
        )
        .then((data) => {
          return data.data;
        });
    }
  };

  getOrderDetails = async (orderNo: string) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const sessionTokenStr = localStorage.getItem(SESSION_TOKEN);
    if (sessionTokenStr !== null) {
      const { token, customerNo } = JSON.parse(sessionTokenStr);
      const orderDetails = await httpClient
        .get<OrderDetails>(`${restOrderDetails}/${customerNo}/${orderNo}`, {
          headers: { token: token },
        })
        .then((data) => {
          return data.data;
        });
      return orderDetails;
    }
  };
}

const getFilterParams = (filter: OrderFilters) => {
  let path: string[] = [];

  if (filter.limit !== undefined) {
    path.push(`limit=${filter.limit}`);
  }
  if (filter.fromDate !== undefined) {
    path.push(`fromDate=${filter.fromDate}`);
  }
  if (filter.offset !== undefined) {
    path.push(`offset=${filter.offset}`);
  }
  if (filter.orderBy !== undefined) {
    path.push(`orderBy=${filter.orderBy}`);
  }
  if (filter.sortingOrder !== undefined) {
    path.push(`sortingOrder=${filter.sortingOrder}`);
  }
  if (filter.toDate !== undefined) {
    path.push(`toDate=${filter.toDate}`);
  }
  return path.join("&");
};
