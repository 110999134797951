import React, { useEffect, useState } from "react";
import { Magnolia } from "domain/Magnolia";

interface ExitSPAProps extends Magnolia.Page {}
const ExitSPA: React.FC<ExitSPAProps> = ({ metadata }) => {
  const [link, setLink] = useState<string>();
  // if we encounter this page the browser shall refresh (hard)
  // this allows to exit the spa and fetch a statically rendered Magnolia Page
  // => compatibility with Freemarker rendered Pages
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.location.reload();
    } else {
      setLink(
        "https://stage.albis.com" + metadata["@path"] + window.location.search
      );
    }
  }, [metadata]);

  return link ? (
    <>
      <p>Developer Info:</p>
      <p>
        This is an URL rendered by the CMS (non-react). You should find the Page
        via this URL. This is handled automatically when deployed.
      </p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    </>
  ) : null;
};

export default ExitSPA;
