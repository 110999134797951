import { de } from "./de";

export const en: typeof de = {
  seoDefaults: {
    albis: {
      title: "ALBIS - Easy, Smart, passionate.",
      brandPrefix: "ALBIS - ",
      description:
        "Distribution of polymers. Technical experts & consultants for all matters relating to plastics. One of the broadest portfolios worldwide.",
    },
    mocom: {
      title: "MOCOM - Compounding excellence.",
      brandPrefix: "MOCOM - ",
      description: "Compounding of polymers.",
    },
    krahnCeramics: {
      title:
        "KRAHN Ceramics - Innovative project partner for ceramic & metal powder processing",
      brandPrefix: "KRAHN CERAMICS - ",
      description:
        "As an experienced technology partner in the field of ceramics & metal, we accompany product developers in realizing tomorrow's ideas along the entire value chain: from ceramic or metal powders to the production of finished parts.",
    },
    ottoKrahnGroup: {
      title: "Otto Krahn Group – Reinvented.",
      brandPrefix: "Otto Krahn Group - ",
      description:
        "Your trusted partner since 111 years for plastics and chemistry has a new structure: ALBIS, KRAHN, MOCOM, WIPAG united in the new holding company.",
    },
    htf: {
      title: "KRAHN Heat Transfer Fluids - Official partner of Eastman",
      brandPrefix: "KRAHN Heat Transfer Fluids - ",
      description:
        "Selection of the right heat transfer fluid from the Therminol®/Marlotherm™ portfolio. Comprehensive advice on heaters, pumps and exhaust air technologies.",
    },
    wipag: {
      title: "WIPAG",

      brandPrefix: "WIPAG - ",
      description:
        "Specialist for the production of compounds by plastics processing as well as the implementation of individual circular economy concepts.",
    },
    plgrd: {
      title: "Playground",
      brandPrefix: "Playground - ",
      description: "To Play With the ground",
    },
    krahnChemie: {
      title: "Krahn Chemie",
      brandPrefix: "Krahn Chemie - ",
      description: "Krahn Chemie",
    },
    okNewBusiness: {
      title: "Otto Krahn New Business",
      brandPrefix: "Otto Krahn New Business",
      description: "Otto Krahn New Business",
    },
  },
  header: {
    contact: "Contact",
    outline: "Overview",
  },
  search: {
    search: "Search",
    label: "Search",
    lastSearches: "Your last searches",
    directEnter: "Direct entrance",
    files: "Files",
    stories: "Articles",
    pages: "Pages",
    products: "Products",
    press: "Press Release",
    industries: "Industries",
    results: "results for",
    more: "show more",
    noResults: "There are no results for",
    adjustSearch: "Please adjust your search and try again.",
    download: "Download",
    short: "Your search term is too short.",
    entrances: {
      albis: [
        {
          link: "/en/products/product-search/",
          label: "Data Sheets",
        },
        {
          link: "/en/services/quality-management",
          label: "Certificates",
        },
        {
          link: "/en/products/brochures",
          label: "Safety Data Sheets",
        },
      ],
      wipag: [
        {
          link: "/en/downloads",
          label: "Downloads & Certificates",
        },
      ],
      mocom: [
        {
          link: "/en/products/product-search#/",
          label: "Data Sheets",
        },
        {
          link: "/en/downloads",
          label: "Safety Data Sheets & Certificates",
        },
      ],
      ottoKrahnGroup: [{ link: "/en/brands", label: "Brands" }],
      krahnChemie: [{ link: "/en/markets", label: "Markets" }],
    },
  },
  products: {
    viewProduct: "View the product",
    back: "Back to overview",
  },
  newsletter: {
    mandatory: "* mandatory field",
  },
  contact: {
    phone: "Phone:",
    fax: "Fax:",
    email: "Email:",
    writeEMail: "Write an E-Mail",
    form: {
      button: "Submit",
      send: "Send",
      placeholder: {
        email: "E-Mail",
        name: "Name",
        company: "Company (optional)",
        companyReq: "Company",
        subject: "Subject",
        street: "Street",
        apartmentNumber: "House number",
        plz: "Zip code",
        city: "City",
        message: "Describe your challenge:",
        country: "Country",
        startup: "Company",
        comment: "Comment",
        phone: "Phone",
      },
      label: {
        message: "Your message",
      },
      success: "Thank you! Your message has been sent.",
      error:
        "An error occured. Please try again later or contact us via info@albis.com.",
    },
  },
  contactFinder: {
    title: "Your Contact",
    description:
      "To help us best answer your questions, please enter your location and postal code here. The appropriate contact person will then be displayed.",
    findAction: "Find",
    zipCodeLabel: "Postal code",
    countryLabel: "Choose country",
    contactPersonTitle: "Contact Person",
    warningMessage:
      "Unfortunately, we could not find a contact person. Please check your input of country and postcode.",
    contactButton: "Ask for Contact",
  },
  contactDialog: {
    title: "Ask for contact",
    contactByPhoneActionLabel:
      "Please call me back at the following phone number:",
    dataProtectionConsentActionLabel:
      "I agree to the <a href='https://www.albis.com/en/privacy' target='_blank'>data privacy policy</a>.",
    requestMsgLabel: "Tell us the reason for your request",
    submit: "Send Message",
    requestMessage: "Insert here",
    successTitle: " We will come back to you",
    successDescription:
      "Your request has been successfully submitted. We will contact you as soon as possible.",
    errorDescription: "Something went wrong.",
    successBtnLabel: "Back to page",
  },
  media: {
    press: {
      categories: {
        products: "Products",
        company: "Company",
        "shows-and-events": "Shows & Events",
        "people-at-albis": "People@ALBIS",
        "healthcare-solutions": "Healthcare Solutions",
        "extrusion": "Extrusion",
        "smart-plastics-at-home": "Smart plastics@home",
        "sustainability": "Sustainability"
      },
      loadMore: "Load more",
    },
    events: {
      addToCalendar: "Add to calendar",
    },
    filter: {
      year: "Year of release",
      topic: "Topic",
    },
    noResults: "No entries",
  },
  markets: {
    cases: "Case Studies",
    productInformation: "Product Information",
    categories: {
      interior: "Interior",
      exterior: "Exterior",
      "under-the-hood": "Under the hood",
      outdoor: "Gardening Tools",
      hvac: "Heating, Venting, Airconditioning",
      furniture: "Furniture",
      kitchenware: "Small Appliances and Housewares",
      sanitary: "Sanitary",
      tools: "Power Tools",
      wintersports: "Mountain- and Wintersports",
      sportswear: "Sports Wear",
      marine: "Water Sports",
      materials: "Materials",
      vehicles: "Vehicles",
      trains: "Trains",
      digger: "Digger",
      solar: "Renewable Energies",
      radio: "Radio",
      video: "Safety and Security Systems",
      computer: "Major Appliances",
      lights: "Lighting Technology",
      electricity: "Electrical Installation",
      machine: "Machines",
      robots: "Robots",
      aircondition: "Air Condition",
      safety: "Safety",
      monitors: "Monitors",
      controlling: "Controlling",
      tubes: "Tubes",
      bedside: "Bedside materials",
      packaging: "Packaging",
      bicycle: "Bike Sports",
      household: "Household aids",
      "post-industrial": "Post industrial recycling",
      renewable: "Renewable energies",
      mechanical: "Mechanial recycling",
      energy: "Energetic recycling",
      biobased: "Biobased ressources",
      "post-consumer": "Post-Consumer recycling",
      diagnostic: "In vitro-diagnostic, labware and bioscience ",
      infusion: "Medical Devices - Infusion systems",
      "drug-delivery": "Medical Devices - Drug delivery systems",
      monitoring: "Medical Devices - Intensive therapy and patient monitoring",
      "medical-packaging":
        "Packaging of pharmaceutical products and medical devices",
      "medical-devices":
        "Other medical devices - application area surgery, rehabilitation, blood donation, vaccination (examples)",
    },
  },
  okg: {
    jobTeaser: {
      student: "Students",
      graduate: "Graduates",
      youngprofessional: "Young Professionals",
      professional: "Professionals",
    },
    pressTeaser: {
      news: "News",
      readMore: "Full article",
    },
    privacy: "Privacy policy",
  },
  htf: {
    tempRange: "Temperature range:",
    modeDetails: "more details",
    close: "close",
    searchPlaceholder: "Search product",
    typicalApplication: "Typical Applications",
    descriptionOfServices: "Description of services",
    productData: "Product Data",
    thermometerDescription:
      "Please move the regulators to filter the products.",
    thermometerMinLabel: "Min. temperature (°C)",
    thermometerMaxLabel: "Max. temperature (°C)",
    resetFilter: "Reset Filter",
    filter: "Filter",
    tempRangeFormat: "#t1#° to #t2#°C",
    productInformation: {
      tempRange: "Temperature range",
      optimalUsageTemp: "Optimal temperature use",
      maxBulkTemp: "Recommended bulk temp °C",
      maxExtendedBulkTemp: "Max. extended bulk temp °C",
      maxFilmTemp: "Max. film temp °C",
      boilingPoint: "Boiling point °C @ 1013 mbar ",
      pumpability: "Pumpability °C @ 300 mm²/s (cSt)",
      pourPoint: "Pour Point °C",
      crystallizingPoint: "Crystallizing Point °C",
      slurryPoint: "Slurry Point °C",
      flashPointD93: "Flash Point °C (ASTM D93)",
      flashPointD92: "Flash Point °C (ASTM D92)",
      autoignitionTempDIN51794: "Autoignition Temp °C (DIN 51794)",
      autoignitionTempASTME659: "Autoignition Temp °C (ASTM E-659)",
      density20: "Density @ 20°C (kg/m³)",
      density25: "Density @ 25°C (kg/m³)",
      density80: "Density @ 80°C (kg/m³)",
      kinematicViscosity25: "Kinematic viscosity @ 25°C (mm²/s)",
      kinematicViscosity40: "Kinematic viscosity @ 40°C (mm²/s)",
      kinematicViscosity80: "Kinematic viscosity @ 80°C (mm²/s)",
      waterClass: "Water Class",
      approval: "Approval",
    },
    pTypicalApplication: {
      "product-ta-oil": "Oil and Gas Processing",
      "product-ta-ng-purification": "Natural Gas Purification",
      "product-ta-refining": "Refining",
      "product-ta-aps": "Asphalt Processing and Storage",
      "product-ta-gtl": "Gas To Liquid (GTL)",
      "product-ta-pp-manufacturing": "Plastics and Polymer Manufacturing",
      "product-ta-shp-manufacturing":
        "Speciality Heat-sensitve Polymer Manufacturing",
      "product-ta-sfm": "Synthetic Fiber Manufacturing",
      "product-ta-plastic-molding": "Plastic Molding",
      "product-ta-pharmaceutical-manufacturing": "Pharmaceutical Manufacturing",
      "product-ta-chiral-chemical-processes": "Chiral Chemical Processes",
      "product-ta-environmental-test-chambers": "Environmental Test Chambers",
      "product-ta-specialty-chemical-manufacturing":
        "Specialty Chemical Manufacturing",
      "product-ta-chemical-manufacturing": "Chemical Manufacturing",
      "product-ta-concentrated-solar-power": "Concentrated Solar Power",
      "product-ta-orc": "Organic Rankine Cycle (ORC)",
      "product-ta-biofuel": "Biofuel Manufacturing",
      "product-ta-foodbeverage-processing": "Food & Beverage Processing",
      "product-ta-mdf": "Medium Density Fiberboard (MDF)",
      "product-ta-desalination": "Desalination",
      "product-ta-htsm": "Heat Transfer System Maintenance",
    },
    checkboxHeadline: "Choose application",
    tempRangeHeadline: "Temperature range °C",
    checkboxFilterLabels: {
      heatingAndRecovery: "Heating/heat recovery",
      heatingAndCooling: "Heating/cooling",
      cooling: "Cooling",
      thermoRegulation: "Thermoregulation",
      foodgrade: "Food grade",
    },
    noProductsFound: "We could not find products matching your criteria.",
    wrongProductId: {
      title: "Produkt not found",
      text: "We could not find products matching your criteria.",
      cta: "To overview",
    },
  },
  krahnCeramics: {
    openImage: "Open Image",
  },
  krahnChemie: {
    contact: {
      form: {
        placeholder: {
          message: "Enter your message",
        }
      }
    }
  },
  scroll: "Scroll to discover",
  brochure: "Brochures",
  productInformation: "Product information",
  innovationChallenge: {
    jobs: {
      student: "Student",
      startUpStaff: "Employee of a Startup interested to join the challenge",
      universityStaff:
        "Researcher/Scientific staff at a university, college, institute or comparable institution",
    },
  },
  redirect: {
    title: "An error occured.",
    text: "Did you mean {{}}?",
    buttonLabel: "To correct page",
  },
  pageNotFound: {
    title: "This page could not be found (404)",
    text: "This page could not be found, please go back.",
    buttonLabel: "Go back",
  },
  clientArea: {
    successMessage: "Your request was send successfully.",
    errorMessage:
      "Something went wrong. Please try again and check your entered data.",
    titleOrder: "Your Order No.: ",
    titleAlbisNr: "Your Albis Order No.: ",
    materialName: "Material Name",
    materialNumber: "Material No.",
    dataSource: "Data Sheet",
    sdb: "SDS",
    apz: "COA",
    orderNr: "Order No.",
    orderNrLong: "Order Number",
    albisNr: "Order No",
    date: "Date",
    name: "Name",
    documentLabel: "Document",
    backToOverview: "Back to order history",
    contactBoxTitle: "Your Contact",
    contactBoxAction: "Request callback",
    orderInsight: {
      orderConfirmation: "Order Confirmation",
      orderNumberLabel: "Your Order No.: ",
      safetySheetLabel: "Safety Data Sheet",
      status: "Status: ",
      estDeliveryDate: "Est. Delivery Date",
      materialQuantity: "Material Quanty ",
      deliveryDocs: "Order Documents",
      albisDeliveries: "Albis Deliveries",
    },
    albisOrderInsight: {
      partOfOrder: "Your Order No.:",
      showOrder: "Show order",
      materialName: "Material Name",
      materialNumber: "Materialno.",
      amount: "Amount",
      chargeId: "Chargeno.",
      materials: "Materials",
    },
    productOrderDialog: {
      title: "Reorder Product",
      description:
        "If you would like to order this product again, please complete the form below and the relevant Albis colleague will contact you.",
      placeholder: {
        company: "Company",
        useCase: "Application (optional)",
        useCase2: "Application",
        technicalRequirements: "Technical Requirements (optional)",
        technicalRequirements2: "Technical Requirements",
        volume: "Volume",
        enterHere: "Please enter",
        color: "Color",
        product: "Product",
      },
      phoneRecallLabel: "Please call me back using the provided number.",
      moreProductsLabel: "I'm interested in additional products.",
      productNameLabel: "Selected Product: ",
      notesLabel: "Additional comments",
      fileUploadLabel: "Upload a document",
      submitBtnLabel: "Send request",
    },
    productRequestDialog: {
      title: "What kind of product are you looking for?",
      description:
        "Unsure on the right material for your application. Fill in the form below and let our experts assist you in your decision.",
    },
    filter: {
      timeRange: "Select delivery time period",
      refresh: "Update",
    },
    sdbHint: "SDS stands for Safety Data Sheets ",
    apzHint: "COA stands for",
    login: {
      title: "Login",
      form: {
        mail: "E-Mail*",
        customerNr: "Customer No.*",
        orderNr: "Order No. (optional)",
        hint:
          "By entering your order number, you can go directly to the order.",
      },
      stayLoggedIn: "Stay logged in",
      modal: {
        headline: "You have received an Email",
        text:
          "We send you a magic link to your email address. If you open the link, you will be referred to the order overview page. ",
      },
    },
    downloadsHint: "Documents Download will be available soon",
    orderDownloadsHint: {
      label: "The document download is currently not available. ",
      link: "Alternativlely, please contact us.",
    },
    moreOrders: "Additional open orders",
    openOrder: "Open Order",
    error: {
      title: "An error occured.",
      albisIdText:
        "Unter dieser Albis Ordernummer konnten wir gerade nichts finden. Bitte überprüfen Sie Ihre Eingabe.",
      orderIdText:
        "Unter dieser Albis Ordernummer konnten wir gerade nichts finden. Bitte überprüfen Sie Ihre Eingabe.",
    },
    upload: {
      title: "Upload a document",
      success: "was uploaded successfully.",
      hint: "Delete file",
      error: "The file upload was not successful. Please try again.",
    },
    startCall: {
      title: "Get in touch...",
      text:
        "If you are interested in more information about your chosen product, complete the form below and our experts will contact you to discuss.",
      productAdornment: "Product: ",
    },
    states: {
      ordered: "Ordered",
      confirmed: "Confirmed",
      scheduled: "Scheduled",
      shipped: "Shipped",
      delivered: "Delivered",
      unknown: "Unknown",
      inProgress: "In Progress",
    },
  },
  recyclingForm: {
    polymer: "Polymer",
    mold: "Mold",
    filler: "Filler",
    color: "Color",
    amount: "Amount in kg",
    description: "Description",
    adhesion: "Other adhesion",
    moreInfo: "Additional Info",
    personalInfo: "Your information",
    name: "Name, Last Name",
    address: "Address",
    email: "E-Mail",
    phone: "Telephone",
    message: "Enter a message here",
    submit: "Submit form",
    save: "Save",
    delete: "Delete",
    add: "Add more waste",
    success: "Thank you! Your message has been sent.",
    error:
      "An error occured. Please try again later or contact us via info@wipag.com.",
    notSaved: "Not saved yet",
  },
  floatingButtonWipag: "Plastic Purchase",
  regions: {
    de: "Germany",
    at: "Austria",
    ch: "Switzerland",
    uk: "United Kingdom",
    ie: "Ireland",
    pl: "Poland",
    ro: "Romania",
    cz: "Czech Republic",
    hu: "Hungary",
    sk: "Slovakia",
    be: "Belgium",
    nl: "Netherlands",
    lu: "Luxembourg",
    it: "Italy",
    fr: "France",
    es: "Spain",
    cn: "China",
    gr: "Greece",
    il: "Israel",
    ru: "Russia",
    se: "Sweden",
    no: "Norway",
    fi: "Finland",
    dk: "Denmark",
    is: "Iceland",
    bg: "Bulgaria",
    lv: "Latvia",
    ee: "Estonia",
    lt: "Lithuania",
    ba: "Bosnia-Herzegovina",
    hr: "Croatia",
    me: "Montenegro",
    mk: "Macedonia",
    si: "Slovenia",
    rs: "Serbia",
    cy: "Cyprus",
    li: "Liechtenstein",
    pt: "Portugal",
    xk: "Kosovo",
    ua: "Ukraine",
  },
  productArea: {
    selectRegion: "See what’s available in your region",
    filterApplication: "Filter by Applications",
    filterSolution: "Filter by Solutions",
    filterIdea: "Filter by Ideas",
    filterManufacturer: "Filter by Manufactorer",
    filterLubricants: "Filter by Lubricants",
    filterFuels: "Filter by Fuels",
    industry: "Select industry",
    contact: "Contact",
    contactButton: "Contact Details",
    back: "Back to overview",
    characteristics: "Product Characteristics",
    brands: "Brands",
    producer: "Producer",
    application: "Application",
    ideas: "Ideas",
    lubricants: "Lubricants",
    fuels: "Fuels",
    manufacturer: "Manufactorer",
    availability: "Available in",
    search: "Search by product name",
    searchResults: "Results for:",
    showAllProducts: "Show all product groups of {{}}",
    noResults: "No results found",
    noContentMaintained:
      "There seems to be no content in this industry. Please come back later!",
    resetFilter: "Reset filter",
    allCountries: "Choose your Country",
  },
  jobpages: {
    applyNow: "Apply now!",
    bePartOfTeam: "Join our team as:",
    tasks: {
      title: "Your tasks",
      main: "Main tasks",
      additional: "Other tasks",
    },
    profile: {
      title: "Your Profile",
      skills: "Experience and know-how",
      personality: "Personality",
    },
    offers: "Offers on the job market",
    showOffers: "Show {{}} jobs",
    selection: "Your selection",

    filterArea: {
      freeText: "Keywords",
      freeTextPlaceholder: "Keyword, Job-ID",
      department: "Function",
      fallbackDepartment: "All functions",
      location: "Location",
      fallbackLocation: "All locations",
      level: "Entry level",
      fallbackLevel: "All entry levels",
      company: "Division",
      fallbackCompany: "All divisions",
    },
    contract: {
      limited: "temporary",
      unlimited: "permanent",
    },
    level: {
      starter: "Job Starters",
      professional: "Professionals",
      students: "Students",
      graduate: "Graduates",
    },
    pagesPerPage: "Jobs per page",
    error: {
      jobDetail:
        "An error occured while loading the job details. Please try again later.",
      jobboard:
        "An error occured while loading the job offers. Please try again later.",
    },
    sortingLabel: "Sort by",
    sortingOptions: {
      companyAsc: "Companies ascending",
      companyDesc: "Companies descending",
      titleAsc: "Job title ascending",
      titleDesc: "Job title descending",
      startOfWorkAsc: "Start of work ascending",
      startOfWorkDesc: "Start of work descending",
      locationAsc: "Location ascending",
      locationDesc: "Location descending",
    },
    departments: {
      finance: "Finance, Accounting & Controlling",
      customerService: "Customer Service",
      graphics: "Graphic Design & Architecture",
      distribution: "Sales & Commerce",
      management: "Management & Corporate Development",
      it: "IT & Software Development",
      law: "Law",
      purchase: "Purchasing, Materials Management & Logistics",
      marketing: "Marketing & Advertising",
      others: "Other Disciplines",
      projectManagement: "Project Management",
      production: "Production & Manufacturing",
      pr: "PR & Journalism",
      processPlanning: "Process Planning & QA",
      teaching: "Teaching, R&D",
      health: "Health, Medical & Social",
      consulting: "Consulting",
      administration: "Administration",
      engineering: "Engineering  & Technical",
      hr: "HR",
      productManagement: "Product Management",
      analysis: "Analysis and statistics",
    },
    errorDetails: {
      title: "Job was not found",
      text:
        "Unfortunately, the requested job ad does not exist anymore. Please run a new search.",
      buttonLabel: "Back",
    },
    errorJobboard: {
      title: "An error occured",
      text: "Loading the job offers failed. Please try again later.",
      buttonLabel: "Try again",
    },
    noJobsFound: {
      title: "Sorry, your search did not return any results.",
      text: "Change your criteria and feel free to try again.",
    },
    position: {
      fulltime: "Full-time",
      parttime: "Part-time",
      intern: "Internship",
      freelance: "Freelancer",
      owner: "Owner",
      partner: "Partner",
      board: "Supervisory Board Member",
      honorary: "Honorary position",
      partFull: "Part-time / Full-time",
      trainee: "Traineeship",
      help: "Temporary help",
      social: "Social commitment",
      apprentice: "Apprenticeship",
      training: "Apprenticeship",
      marginal: "Marginal",
      student: "Working student",
      temp: "Temporary / Seasonal",
      dual: "Dual study",
    },
  },
  a11y: {
    showContent: "Activate to show content for {{}}",
  },
};
