import {
  DynamicContentResponse,
  getDynamicContentFromResponse,
} from "domain/jobpages/DynamicContent";
import { getAPIBase } from "util/MagnoliaAppHelpers";
import { HTTPClientService } from "./HTTPClient";

const basePath = getAPIBase();
const restJobContentPath =
  basePath + process.env.REACT_APP_MGNL_API_JOBS_DYNAMIC_CONTENT;

export default class DynamicJobContentService {
  readonly httpClientService: HTTPClientService;
  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  getDefaultDynamicContent = async (department: string) => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    const { data } = await httpClient.get(
      // DO NOT CHANGE THIS PATH
      restJobContentPath + "?limit=25&@ancestor=/global"
    );

    const resContent = data.results.find(
      (content: DynamicContentResponse) => content.department === department
    );

    if (resContent) {
      return getDynamicContentFromResponse(resContent);
    } else {
      return undefined;
    }
  };

  getSpecificDynamicContent = async (
    company: string,
    jobId: string,
    department: string
  ) => {
    const httpClient = this.httpClientService.getDefaultHttpClient();

    const { data } = await httpClient.get(
      restJobContentPath + "?limit=25&@ancestor=/" + company
    );
    // Check if there is job specific content
    const resJobContent: DynamicContentResponse = data.results.find(
      (content: DynamicContentResponse) => content["job-id"] === jobId
    );

    // Check if there is company specific content
    const resCompanyContent: DynamicContentResponse = data.results.find(
      (content: DynamicContentResponse) => content.department === department
    );
    if (resJobContent) {
      return getDynamicContentFromResponse(resJobContent);
    } else if (resCompanyContent) {
      return getDynamicContentFromResponse(resCompanyContent);
    } else {
      return undefined;
    }
  };
}
