import { HTTPClientService } from "services/HTTPClient";
import queryString from "query-string";
import { getAPIBase } from "../util/MagnoliaAppHelpers";
import flatten from "lodash/flatten";
import { Magnolia } from "domain/Magnolia";

const basePath = getAPIBase();
const restBasePressEntries = basePath + process.env.REACT_APP_MGNL_API_PRESS;
const restBasePressDetail =
  basePath + process.env.REACT_APP_MGNL_API_PRESS_DETAIL;
const restBasePressContact =
  basePath + process.env.REACT_APP_MGNL_API_PRESS_CONTACT;

type LoadConfig = {
  filter?: {
    orderBy: string;
    limit: number;
    offset: number;
  };
  ancestor?: string;
};

export default class ContentService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  loadStories = async (
    { filter, ancestor }: LoadConfig = {
      filter: { orderBy: "created desc", limit: 9, offset: 0 },
      ancestor: "/albis/de/press/2020",
    }
  ) => {
    const httpClient = this.httpClientService.getDefaultHttpClient();

    const { data } = await httpClient.get<{ results: Magnolia.MediaTeaser[] }>(
      `${restBasePressEntries}?${queryString.stringify(
        filter!
      )}&@ancestor=${ancestor}`
    );

    return data;
  };

  loadStoryDetail = async (id: string) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get<{ results: Magnolia.MediaDetail[] }>(
      `${restBasePressDetail}?@jcr:uuid=${id}`
    );

    return data.results[0];
  };

  loadStoryContact = async (name: string) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const { data } = await httpClient.get(`${restBasePressContact}${name}`);

    return data;
  };

  getYearsForFilter = async ({ ancestor }: LoadConfig) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const { data } = await httpClient.get(
      `/.rest/delivery/stories/years/v1/${ancestor}`
    );
    return data["@nodes"];
  };

  getTagsForFilter = async ({ filter, ancestor }: LoadConfig) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    const {
      data: { results },
    } = await httpClient.get(
      `/.rest/delivery/stories/tags/v1?${queryString.stringify(
        filter!
      )}@ancestor=${ancestor}&limit=100`
    );

    let topics: string[] = flatten<string>(
      results.map((result: { "mgnl:tags"?: string[] }) => result["mgnl:tags"])
    ).filter(
      (value, index, array) =>
        array.indexOf(value) === index && value !== undefined
    );

    return topics;
  };
}
