import { Magnolia } from "domain/Magnolia";
import { Image } from "domain/image";

export interface DynamicContentResponse {
  title?: Magnolia.Text;
  "title-en"?: Magnolia.Text;
  text?: Magnolia.RichText;
  "text-en"?: Magnolia.RichText;
  quote?: Magnolia.RichText;
  "quote-en"?: Magnolia.RichText;
  "quote-testimonial"?: Magnolia.Text;
  "quote-testimonial-en"?: Magnolia.Text;
  "quote-image"?: Magnolia.Image;
  "additional-title"?: Magnolia.Text;
  "additional-title-en"?: Magnolia.Text;
  "additional-text"?: Magnolia.RichText;
  "additional-text-en"?: Magnolia.RichText;
  "additional-image"?: Magnolia.Image;
  department?: Magnolia.Text;
  company?: Magnolia.Text;
  "job-id"?: Magnolia.Text;
}

export interface DynamicContent {
  title?: string;
  titleEn?: string;
  text?: string;
  textEn?: string;
  quote?: string;
  quoteEn?: string;
  quoteTestimonial?: string;
  quoteTestimonialEn?: string;
  quoteImage?: Image;
  additionalTitle?: string;
  additionalTitleEn?: string;
  additionalText?: string;
  additionalTextEn?: string;
  additionalImage?: Image;
  department?: string;
  company?: string;
}

export const getDynamicContentFromResponse = (
  res: DynamicContentResponse
): DynamicContent => {
  return {
    title: res.title,
    titleEn: res["title-en"],
    text: res.text,
    textEn: res["text-en"],
    quote: res.quote,
    quoteEn: res["quote-en"],
    quoteTestimonial: res["quote-testimonial"],
    quoteTestimonialEn: res["quote-testimonial-en"],
    quoteImage: res["quote-image"]
      ? {
          source: res["quote-image"]?.renditions.cover600x600.link ?? "",
          alt: res["quote-image"]?.["@name"] ?? "",
        }
      : undefined,
    additionalTitle: res["additional-title"],
    additionalTitleEn: res["additional-title-en"],
    additionalText: res["additional-text"],
    additionalTextEn: res["additional-text-en"],
    additionalImage: res["additional-image"]
      ? {
          source: res["additional-image"]?.renditions.cover690x490.link ?? "",
          alt: res["additional-image"]?.["@name"] ?? "",
        }
      : undefined,
    department: res.department,
    company: res.company,
  };
};
