import { useEffect } from "react";
import { languageState } from "./language";
import { useRecoilValue } from "recoil";

export const PERSIST_KEY_LANGUAGE = "albis:state:language";

export const useStatePersistence = () => {
  const language = useRecoilValue(languageState);

  useEffect(() => {
    localStorage.setItem(PERSIST_KEY_LANGUAGE, language);
  }, [language]);
};
