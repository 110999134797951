import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  SearchRequest,
  SearchRequestType,
  SearchResult,
  SingleSearchResponse,
  SingleSearchResult,
} from "domain/Search";
import { HTTPClientService } from "services/HTTPClient";
import { languageState } from "state/language";
import {
  getAPIBase,
  getBrandFromLocation,
  getCurrentMultisiteBase,
} from "util/MagnoliaAppHelpers";
import Services from "./Services";

const basePath = getAPIBase();

const restSearchBase = basePath + process.env.REACT_APP_MGNL_API_SEARCH;
const restSuggestionsBase =
  basePath + process.env.REACT_APP_MGNL_API_AUTOCOMPLETE;

export const SEARCH_RESULT_LIMIT = 5;
export default class SearchService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  searchByType = (
    type: SearchRequestType,
    offset: number,
    query: string,
    lang: string,
    website: string
  ): Promise<SingleSearchResponse> => {
    const request: SearchRequest = {
      type,
      lang,
      query,
      website,
      offset,
      highlightConfig: {
        maxNumFragments: 1,
        fragmentSize: 200,
        separator: " ...",
      },
      limit: SEARCH_RESULT_LIMIT,
    };
    const httpClient = this.httpClientService.getAlbisHttpClient();

    return httpClient
      .post(`${restSearchBase}`, request)
      .then(({ data }) => data);
  };

  autocomplete = (query: string, lang: string, website: string) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();

    const request = {
      query,
      lang,
      limit: 5,
      website,
    };

    return httpClient
      .post(`${restSuggestionsBase}`, request)
      .then(({ data }) => data);
  };
}

const startsWithCapital = (word: string) => {
  return word.charAt(0) === word.charAt(0).toUpperCase();
};

const mergeSearchURL = (type: SearchRequestType, reqLang: string) => (
  result: SingleSearchResult
): SingleSearchResult => {
  let {
    path,
    name,
    id,
    brandPath: brandPathFromProd,
    slug,
    productGroups,
  } = result;

  const currentBrand = getBrandFromLocation();
  if (type === "assets") {
    // do nothing! -> link is /dam/jcrResource...
    return { ...result, path };
  }

  // path = /albis/de/path1/path2/ --> otherPathParts = [de, path1, path2]
  let newPath = "/";
  let newName = name;

  if (path) {
    let [, , ...otherPathParts] = path?.split("/");
    newPath = "/" + otherPathParts.join("/");

    if (type === "products") {
      // path = "/de/albis-technical-compounds/Covestro/makrolon",
      const pathParts = path?.toLowerCase().split("/");

      let [, , , brandPath, product] = pathParts;
      if (currentBrand === "wipag") {
        [, , brandPath, product] = pathParts;
      }

      let baseAppPath = "";
      if (currentBrand === "albis") {
        baseAppPath = `/${reqLang}/products/products-brands`;
      } else {
        baseAppPath = `/${reqLang}/products/products-overview`;
      }

      if (product !== undefined) {
        // append product
        // brandPathFromProd because: a "mocom" brand named "alcom" in albis  ;-(
        if (brandPathFromProd && startsWithCapital(brandPathFromProd)) {
          newPath = `${baseAppPath}/${product}`;
        } else {
          newPath = `${baseAppPath}/${brandPathFromProd}/${product}`;
        }
      } else {
        // append brand
        newPath = `${baseAppPath}/${brandPath}`;
      }

      if (process.env.NODE_ENV === "development") {
        newPath = `/${getCurrentMultisiteBase()}${newPath}`;
      }
    }

    // link to MagnboliaMediaApp
    if (type === "stories") {
      if (newPath.indexOf("/press/") >= 0) {
        newPath = `/${reqLang}/info-center/press~${name}~s=${id}~`;
      }
      if (newPath.indexOf("/blog/") >= 0) {
        newPath = `/${reqLang}/info-center/blog~${name}~s=${id}~`;
      }
    }

    // slug
    if (type === "pages") {
      if (slug !== undefined) {
        newPath = path + "/" + slug;
      } else {
        newPath = path;
      }
    }
  }
  if (type === "industries") {
    const basePath = `/${reqLang}/industries`;
    newPath = `${basePath}/${id}`;
    if (productGroups && productGroups?.length !== 0) {
      // we can use it this way because there will only be one PG per result
      const firstPG = productGroups[0];
      newPath = `${basePath}/${id}/${firstPG.id}`;
      newName = `${name}: ${firstPG.name}`;
    }
    if (process.env.NODE_ENV === "development") {
      newPath = `/${getCurrentMultisiteBase()}${newPath}`;
    }
  }
  return { ...result, path: newPath, name: newName };
};

export function useSearchByType(
  searchString: string,
  type: SearchRequestType
): [SearchResult, () => void] {
  const [state, setState] = useState<SearchResult>({
    data: [],
    count: 0,
    offset: 0,
    loading: true,
  });
  const oldData = state.data;
  const lang = useRecoilValue(languageState);
  const brand = getBrandFromLocation();

  const requestResults = useCallback(
    (dataBefore: SingleSearchResult[], query: string, newOffset: number) => {
      if (query.length < 3 || !brand) {
        setState({
          data: [],
          count: 0,
          offset: 0,
          loading: false,
        });
        return;
      }
      setState((state) => ({
        ...state,
        loading: true,
      }));

      Services.search
        .searchByType(type, newOffset, query, lang, brand)
        .then((response) => {
          setState({
            data: [
              ...dataBefore,
              ...response.data.map(mergeSearchURL(type, lang)),
            ],
            count: response.count,
            offset: newOffset,
            loading: false,
          });
        })
        .catch((e) => {
          setState((state) => ({
            ...state,
            loading: false,
          }));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showMoreFiles = useCallback(() => {
    requestResults(oldData, searchString, state.offset + SEARCH_RESULT_LIMIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.offset, oldData, type, lang]);

  useEffect(() => {
    // initial or/and on searchString change
    requestResults([], searchString, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return [state, showMoreFiles];
}
