import React from "react";

class MagnoliaErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: undefined as undefined | Error,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // componentDidCatch(error: Error, errorInfo: any) {
  //   // You can also log the error to an error reporting service
  // }

  render() {
    if (this.state.hasError) {
      // if (process.env.REACT_APP_ENV === "prod") {
      return null;
      // } else {
      //   return (
      //     <p>
      //       Something went wrong while rendering the component.
      //       <br /> Our team has been informed about this.
      //       <p>{this.state.error!.message}</p>
      //     </p>
      //   );
      // }
    }

    return this.props.children;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wrapWithErrorBoundary = (Component: any) => (props: any) => {
  return (
    <MagnoliaErrorBoundary>
      {/* For some reason we need this div to prevent an endless loop 😬 */}
      <div>
        <Component {...props} />
      </div>
    </MagnoliaErrorBoundary>
  );
};
