import EditorContextHelper from './EditorContextHelper';

export const getSelectedVariant = (content, templateAnnotations) => {
  if (!content || !templateAnnotations) {
    return content;
  }
  const annotations = templateAnnotations[content?.['@path']];
  const match = annotations ? annotations.match(/selectedVariant="(.+)"/) : null;
  if (!match) return content;
  const variant = match[1];
  return variant === content['@name'] ? content : content[variant];
};

export const getVariant = (content, templateAnnotations) => {
  if (!EditorContextHelper.inIframe() || EditorContextHelper.inPreviewAsVisitor()) {
    return content;
  }

  return getSelectedVariant(content, templateAnnotations);
};

/**
 * Wrap template annotations in order to resolve personalized content.
 *
 * Search for 'selectedVariant' in the template annotations and try to replace the value
 * of the aforementioned attribute with selected one by the variant select.
 *
 * Server does not know which variant was selected, thus we store it in the client app session on
 * variant select change.
 */
export const wrap = (templateAnnotations, selectedComponentVariants) => {
  if (!selectedComponentVariants) {
    return templateAnnotations;
  }
  const modified = {};
  const selectedVariantRegex = /selectedVariant="(.+?)"/;
  Object.entries(templateAnnotations).forEach(entry => {
    const [path, comment] = entry;
    if (selectedVariantRegex.test(comment) && path in selectedComponentVariants) {
      let selectedVariant = selectedComponentVariants[path];
      // name of the 'original' variant has to be remapped to the node name
      // get it from the path
      if (selectedVariant === 'original') {
        selectedVariant = path.split('/').pop();
      }
      modified[path] = comment.replace(selectedVariantRegex, `selectedVariant="${selectedVariant}"`);
    }
  });
  // merge the template annotations with modified annotations
  return { ...templateAnnotations, ...modified };
};

export default {
  getVariant,
  wrap
};
