function getUrl(string) {
  try {
    const url = /^http(s?):\/\//i.test(string)
      ? string
      : 'http://valid-url.com' + (string.startsWith('/') ? string : '/' + string);

    return new URL(url);
  } catch (e) {
    console.warn('requestUrl is not a valid URL'); // eslint-disable-line no-console

    return false;
  }
}

export default function getMagnoliaContext(requestUrl, spaRootNodePath, languages) {
  const url = getUrl(requestUrl);
  const magnoliaContext = {
    isMagnolia: false,
    isMagnoliaEdit: false,
    isMagnoliaPreview: false,
    version: undefined,
    nodePath: undefined,
    currentLanguage: undefined,
    searchParams: undefined,
    search: undefined
  };

  if (url) {
    const pathname = url.pathname;
    const searchParams = new URLSearchParams(url.search);
    const mgnlPreview = searchParams.get('mgnlPreview');
    const mgnlPreviewAsVisitor = searchParams.get('mgnlPreviewAsVisitor');
    const mgnlVersion = searchParams.get('mgnlVersion');
    const preview = mgnlPreview == null ? mgnlPreviewAsVisitor : mgnlPreview;

    if (preview) {
      magnoliaContext.isMagnolia = true;

      if (preview === 'false') {
        magnoliaContext.isMagnoliaEdit = true;
      }
      if (preview === 'true') {
        magnoliaContext.isMagnoliaPreview = true;
      }
    }

    if (magnoliaContext.isMagnolia && !mgnlPreviewAsVisitor) {
      searchParams.set('variants', 'all');
    }

    if (mgnlVersion) {
      magnoliaContext.version = mgnlVersion;
      searchParams.set('version', mgnlVersion);
    }

    if (languages) {
      magnoliaContext.currentLanguage = languages[0];

      languages.some(function (language) { // eslint-disable-line func-names
        if (new RegExp('/' + language + '($|/)').test(pathname)) {
          magnoliaContext.currentLanguage = language;

          return true;
        }

        return false;
      });

      searchParams.set('lang', magnoliaContext.currentLanguage);
    }

    if (spaRootNodePath) {
      magnoliaContext.nodePath = spaRootNodePath + pathname.replace(new RegExp('(.*' + spaRootNodePath + '|.html|/$)', 'g'), '');

      if (magnoliaContext.currentLanguage) {
        magnoliaContext.nodePath = magnoliaContext.nodePath.replace(
          new RegExp('/' + magnoliaContext.currentLanguage + '($|/)'),
          '/'
        );
      }

      if (magnoliaContext.nodePath.slice(-1) === '/') magnoliaContext.nodePath = magnoliaContext.nodePath.slice(0, -1);
    }

    magnoliaContext.searchParams = Object.fromEntries(searchParams);
    magnoliaContext.search = '?' + searchParams.toString();
  }

  return magnoliaContext;
}
