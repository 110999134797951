import { RecycableProduct } from "domain/RecyclingForm/RecycableProduct";
import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

export interface RecyclingMail {
  target: "WIPAG" | "PROTOFY";
  name: string;
  address: string;
  email: string;
  phone?: string;
  message?: string;
  wastes: RecycableProduct[];
}

const basePath = getAPIBase();
const restContactMailBase =
  basePath + process.env.REACT_APP_MGNL_API_WASTE_FORM;

export default class RecyclingFormService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  sendRecyclingFormEmail = async (mail: RecyclingMail) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    return await httpClient.post(restContactMailBase, mail);
  };
}
