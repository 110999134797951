import { IndustryPreview } from "domain/KChMarkets/Industry";
import { enrichIndustryResponse } from "domain/util/enrichIndustryResponse";
import { HTTPClientService } from "./HTTPClient";

// TODO: add to environment variables
const basePath = "https://krahnchemieapi.azurewebsites.net/api/";

export default class KChMarketsService {
  readonly httpClientService: HTTPClientService;
  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  getCountries = async (): Promise<string[]> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    try {
      const { data } = await httpClient.get(basePath + "Countries");
      return data;
    } catch (e) {
      console.error("Krahn Chemie getCountries() failed", e);
      throw e;
    }
  };

  getCountriesForIndustry = async (industryId: string): Promise<string[]> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    try {
      const { data } = await httpClient.get<{code: string, name: string}[]>(
        basePath + "available-countries/" + industryId
      );

      if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
      }
      
      return data.map((country) => country.code);
    } catch (e) {
      console.error("Krahn Chemie getCountriesForIndustry() failed", e);
      throw e;
    }
  }

  getIndustries = async (): Promise<IndustryPreview[]> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    try {
      const { data } = await httpClient.get(basePath + "Industries");
      const enrichedIndustries = enrichIndustryResponse(data);
      return enrichedIndustries;
    } catch (e) {
      console.error("Krahn Chemie getIndustries() failed", e);
      throw e;
    }
  };

  getIndustryData = async (industryId: string, country: string) => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    try {
      const { data } = await httpClient.get(
        basePath + "Industry/" + industryId + "/" + country
      );
      return data[0];
    } catch (e) {
      console.error("Krahn Chemie getIndustryData() failed", e);
      throw e;
    }
  };
}
