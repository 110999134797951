import { IndustryPreview, IndustryResponse } from "domain/KChMarkets/Industry";
import IndCleanImage from "assets/krahnChemieIndustries/KRAHN_IndustrialCleaningHomeCare.jpg";
import PersCareImage from "assets/krahnChemieIndustries/KRAHN_PersonalCare.jpg";
import RubberImage from "assets/krahnChemieIndustries/KRAHN_Rubber.jpg";
import AdhSealImage from "assets/krahnChemieIndustries/KRAHN_adhesives_sealants.jpg";
import ChemInterImage from "assets/krahnChemieIndustries/KRAHN_chemical_intermediates.jpg";
import CoatingsImage from "assets/krahnChemieIndustries/KRAHN_coatings.jpg";
import ConstructionImage from "assets/krahnChemieIndustries/KRAHN_construction.jpg";
import FuelsLubImage from "assets/krahnChemieIndustries/KRAHN_fuels_lubricants.jpg";
import PlasticsImage from "assets/krahnChemieIndustries/KRAHN_plastics.jpg";

export const enrichIndustryResponse = (
  industryResponse: IndustryResponse[]
): IndustryPreview[] => {
  const enrichedIndustries = industryResponse.map((industry) => {
    let tileImage = "";
    switch (industry.id) {
      case "4390479f-1188-4f20-925d-3df43c966647":
        tileImage = IndCleanImage;
        break;
      case "4a7dfb51-d769-432d-accb-1d1f4234708f":
        tileImage = PersCareImage;
        break;
      case "f7ee65a2-72d3-4eb4-8955-6b22538b886b":
        tileImage = RubberImage;
        break;
      case "f92977e6-9aaf-4239-86d1-0a0600960ecf":
        tileImage = AdhSealImage;
        break;
      case "711c1b85-a68c-4389-8913-80fe2b6c3645":
        tileImage = ChemInterImage;
        break;
      case "600ddb36-1351-4cc0-82e4-ad35abab5be2":
        tileImage = CoatingsImage;
        break;
      case "691d91b8-cd41-4d0b-9424-ce6852c0dc10":
        tileImage = ConstructionImage;
        break;
      case "567cb75d-3fdc-46d7-8162-dbdd0c1dc097":
        tileImage = FuelsLubImage;
        break;
      case "29dd17fe-2cf4-4f5d-8565-861546451465":
        tileImage = PlasticsImage;
        break;
      default:
        break;
    }
    return {
      ...industry,
      tileImage: tileImage,
    };
  });
  return enrichedIndustries;
};
