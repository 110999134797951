import { AxiosResponse } from "axios";
import {
  OnlyfyJob,
  OnlyfyJobResponse,
  Seniority,
  getOnlyfyJobFromResponse,
} from "domain/jobpages/OnlyfyJob";
import { getOnlyfyJobFromSpreadsheet } from "util/jobpages/getOnlyfyJobFromSpreadsheet";
import { HTTPClientService } from "./HTTPClient";

export interface SpreadsheetRowResponse {
  majorDimension: "ROWS";
  range: string;
  values: string[][];
}

const basepath = process.env.REACT_APP_ONLYFY_API ?? "";

export default class OnlyfyJobsService {
  readonly httpClientService: HTTPClientService;
  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  private filterJobsByPublishAndLanguage = (
    resJobs: OnlyfyJobResponse[],
    language: string,
    seniority?: Seniority,
    debug = false
  ) => {
    const isEN = language.toLowerCase() === "en";
    let filteredJobs = [...resJobs];

    filteredJobs = filteredJobs.filter((item: OnlyfyJobResponse) => {
      const hasJobboardPublish = item.custom_fields.find(
        (field) => field.name === "jobboardPublish"
      );

      const hasJobbardPublishEndDate = item.custom_fields.find(
        (field) => field.name === "jobboardPublishEnddate"
      );

      const hasJobbardPublishDate = item.custom_fields.find(
        (field) => field.name === "jobboardPublishDate"
      );

      if (hasJobboardPublish) {
        const isPublished = debug
          ? true
          : hasJobboardPublish?.values[0].value === "Ja";

        if (hasJobbardPublishDate) {
          if (hasJobbardPublishEndDate) {
            return (
              isPublished &&
              new Date(hasJobbardPublishDate.values[0].value) < new Date() &&
              new Date(hasJobbardPublishEndDate.values[0].value) > new Date()
            );
          }
          return (
            isPublished &&
            new Date(hasJobbardPublishDate.values[0].value) < new Date()
          );
        }

        if (hasJobbardPublishEndDate) {
          return (
            isPublished &&
            new Date(hasJobbardPublishEndDate.values[0].value) > new Date()
          );
        }
        return isPublished;
      } else {
        return false;
      }
    });
    // filter for language only relevant on en (all jobs that are not de)
    if (isEN) {
      filteredJobs = filteredJobs.filter((job: OnlyfyJobResponse) => {
        return (
          job.custom_fields.find((field) => field.name === "websiteLanguage")
            ?.values[0].value !== "de"
        );
      });
    }

    return filteredJobs
      .map((res: OnlyfyJobResponse) => getOnlyfyJobFromResponse(res))
      .filter((job: OnlyfyJob) =>
        job.company !== undefined && seniority
          ? job.seniority === seniority
          : true
      );
  };

  private getJobsFromSpreadsheet = (
    response: SpreadsheetRowResponse,
    isEN: boolean
  ): OnlyfyJob[] => {
    return response.values
      .filter((item) => {
        if (isEN) {
          return item[1] !== "" && item[3] !== "de";
        } else {
          return item[1] !== "";
        }
      })
      .filter((item) => item.length > 0)
      .map((row: string[]) => {
        return getOnlyfyJobFromSpreadsheet(row);
      })
      .filter((job: OnlyfyJob | undefined) => job !== undefined) as OnlyfyJob[];
  };

  private getPageFromUrl = (url: string) => {
    const urlParams = new URLSearchParams(url);
    const page = urlParams.get("page");

    return page ? parseInt(page) : 1;
  };

  private loadJobsFromOnlyfy = async (): Promise<OnlyfyJobResponse[]> => {
    const pageSize = 25;
    let page = 1;
    let url = `${basepath}?finished_at=null&page_size=${pageSize}&page=${page}`;
    const httpClient = this.httpClientService.getDefaultHttpClient();
    const promises: Promise<
      AxiosResponse<{
        data: OnlyfyJobResponse[];
      }>
    >[] = [];

    // Make a dry run to get the last page
    const {
      data: {
        links: { last },
      },
    } = await httpClient.get<{
      data: OnlyfyJobResponse[];
      links: { last: string };
    }>(url, {
      headers: { apiKey: process.env.REACT_APP_ONLYFY_API_KEY },
    });

    // As long the current page is less or equal to the last page, add it to the result
    while (page <= this.getPageFromUrl(last)) {
      const p = httpClient.get<{
        data: OnlyfyJobResponse[];
      }>(url, {
        headers: { apiKey: process.env.REACT_APP_ONLYFY_API_KEY },
      });

      promises.push(p);
      page++;
      url = `${basepath}?finished_at=null&page_size=${pageSize}&page=${page}`;
    }

    // Wait for all promises to resolve and return the data
    return (await Promise.all(promises)).map((res) => res.data.data).flat();
  };

  loadJobs = async (
    language: string,
    callCount: number = 1,
    debug = false
  ): Promise<OnlyfyJob[]> => {
    const TOTAL_RETRIES = 5;

    try {
      const httpClient = this.httpClientService.getDefaultHttpClient();

      const onlyfyRes = await this.loadJobsFromOnlyfy();

      const spreadsheetRes = await httpClient.get<SpreadsheetRowResponse>(
        "/ext/jobs"
      );

      const isEN = language.toLowerCase() === "en";

      const jobs = this.filterJobsByPublishAndLanguage(
        onlyfyRes,
        language,
        undefined,
        debug
      );
      const spreadsheetJobs: OnlyfyJob[] = this.getJobsFromSpreadsheet(
        spreadsheetRes.data,
        isEN
      );

      return jobs.concat(spreadsheetJobs);
    } catch (error) {
      if (callCount >= TOTAL_RETRIES) {
        console.error(
          `OnlyfyJobsService: loadJobs: ${TOTAL_RETRIES} retries failed`
        );
        throw error;
      }

      console.info(
        `OnlyfyJobsService: loadJobs: promises.length < 10, retrying (${callCount})...`
      );
      return this.loadJobs(language, callCount + 1);
    }
  };

  loadJobsByDepartment = async (
    department: number,
    language: string
  ): Promise<OnlyfyJob[]> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();

    const res = await httpClient.get(
      basepath + `?finished_at=null&page_size=100&department_id=${department}`,
      {
        headers: { apiKey: process.env.REACT_APP_ONLYFY_API_KEY },
      }
    );

    const jobs = this.filterJobsByPublishAndLanguage(res.data.data, language);

    return jobs;
  };

  loadJobsByCompany = async (
    company: string,
    seniority: Seniority,
    language: string
  ): Promise<OnlyfyJob[]> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();

    const { data } = await httpClient.get(
      basepath + "?finished_at=null&&page_size=100&&instance[name]=" + company,
      {
        headers: { apiKey: process.env.REACT_APP_ONLYFY_API_KEY },
      }
    );

    const jobs = this.filterJobsByPublishAndLanguage(
      data.data,
      language,
      seniority
    );

    return jobs;
  };

  loadJobDetails = async (id: string): Promise<OnlyfyJob> => {
    const httpClient = this.httpClientService.getDefaultHttpClient();
    const { data } = await httpClient.get(basepath + "/" + id, {
      headers: { apiKey: process.env.REACT_APP_ONLYFY_API_KEY },
    });

    return getOnlyfyJobFromResponse(data);
  };
}
