import { HTTPClientService } from "services/HTTPClient";
import queryString from "query-string";
import { getAPIBase } from "../util/MagnoliaAppHelpers";

const basePath = getAPIBase();
const restBaseEventEntries = basePath + process.env.REACT_APP_MGNL_API_EVENTS;

type LoadConfig = {
  filter?: {
    orderBy?: string;
    limit: number;
    offset?: number;
  };
  ancestor?: string;
};

export default class EventService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  loadEvents = async (
    { filter, ancestor }: LoadConfig = {
      filter: { orderBy: "from-datetime asc", limit: 500, offset: 0 },
      ancestor: "albis",
    }
  ) => {
    try {
      const httpClient = this.httpClientService.getDefaultHttpClient();

      const { data } = await httpClient.get(
        `${restBaseEventEntries}?@ancestor=/${ancestor}&${queryString.stringify(
          filter!
        )}&lang=all`
      );
      return data;
    } catch {}
  };
}
