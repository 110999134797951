import ContactService from "services/Contact";
import ContentService from "services/Content";
import EventService from "services/Events";
import { HTTPClientService } from "services/HTTPClient";
import PressService from "services/Press";
import ProductService from "services/Product";
import RecyclingFormService from "services/RecyclingForm";
import SearchService from "services/Search";
import ContactFinderService from "services/client-area/ContactFinder";
import ClientAreaContactService from "services/client-area/ContactForms";
import LoginService from "services/client-area/Login";
import OrderService from "services/client-area/Order";
import DynamicJobContentService from "./DynamicJobContent";
import KChMarketsService from "./KCh-Markets";
import OnlyfyJobsService from "./OnlyfyJobs";

class ServicesContainer {
  private httpClient: HTTPClientService;
  content: ContentService;
  product: ProductService;
  press: PressService;
  events: EventService;
  contact: ContactService;
  contactFinder: ContactFinderService;
  search: SearchService;
  login: LoginService;
  recyclingForm: RecyclingFormService;
  clientContact: ClientAreaContactService;
  order: OrderService;
  onlyfyJobs: OnlyfyJobsService;
  dynamicJobContent: DynamicJobContentService;
  kChMarkets: KChMarketsService;

  constructor() {
    this.httpClient = new HTTPClientService();

    this.content = new ContentService(this.httpClient);
    this.product = new ProductService(this.httpClient);
    this.press = new PressService(this.httpClient);
    this.events = new EventService(this.httpClient);
    this.contact = new ContactService(this.httpClient);
    this.contactFinder = new ContactFinderService(this.httpClient);
    this.login = new LoginService(this.httpClient);
    this.search = new SearchService(this.httpClient);
    this.recyclingForm = new RecyclingFormService(this.httpClient);
    this.clientContact = new ClientAreaContactService(this.httpClient);
    this.order = new OrderService(this.httpClient);
    this.onlyfyJobs = new OnlyfyJobsService(this.httpClient);
    this.dynamicJobContent = new DynamicJobContentService(this.httpClient);
    this.kChMarkets = new KChMarketsService(this.httpClient);
  }
}

const Services = new ServicesContainer();

export default Services;
