import React from "react";
import { CircularProgress, Box } from "@mui/material";

const LoadingIndicator = ({
  color = "primary",
}: {
  color?: "primary" | "secondary" | "inherit";
}) => (
  <Box flex={1} display="flex" alignItems="center" justifyContent="center">
    <CircularProgress color={color} />
  </Box>
);

export default LoadingIndicator;
