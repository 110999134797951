import axios, { AxiosError, AxiosInstance } from "axios";
import * as errors from "domain/Error";

// 4xx
const HTTP_BAD_REQUEST = 400;
const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;
const HTTP_NOT_FOUND = 404;
const HTTP_PAYLOAD_TOO_LARGE = 413;
const HTTP_CONFLICT = 409;

// 5xx
const HTTP_INTERNAL_SERVER_ERROR = 500;

function errorRequestHandler(error: AxiosError | Error) {
  console.error("errorRequestHandler", error);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(error as any).isAxiosError) {
    throw new errors.InternalError(
      `Internal error: ${error.name} ${error.message}`
    );
  }

  const axiosError = error as AxiosError;

  if (axiosError.request) {
    // axios network error
    return new errors.ServerError("network error");
  }

  return Promise.reject(axiosError);
}

function errorResponseHandler(error: AxiosError | Error) {
  console.error("errorResponseHandler", error);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(error as any).isAxiosError) {
    throw new errors.InternalError(
      `Internal error: ${error.name} ${error.message}`
    );
  }

  const axiosError = error as AxiosError;

  if (axiosError.response) {
    if (axiosError.response.status === HTTP_INTERNAL_SERVER_ERROR) {
      throw new errors.ServerError(
        `Internal server error: ${axiosError.message}`
      );
    }

    if (axiosError.response.status === HTTP_UNAUTHORIZED) {
      throw new errors.UnauthorizedError(axiosError.response.statusText);
    }

    if (axiosError.response.status === HTTP_FORBIDDEN) {
      throw new errors.ForbiddenError(axiosError.response.statusText);
    }

    if (axiosError.response.status === HTTP_BAD_REQUEST) {
      throw new errors.BadRequestError(
        axiosError.response.statusText,
        axiosError.response.data
      );
    }

    if (axiosError.response.status === HTTP_NOT_FOUND) {
      throw new errors.NotFoundError(axiosError.response.statusText);
    }

    if (axiosError.response.status === HTTP_PAYLOAD_TOO_LARGE) {
      throw new errors.PayLoadTooLargeError(axiosError.response.statusText);
    }

    if (axiosError.response.status === HTTP_CONFLICT) {
      throw new errors.ConflictError(axiosError.response.statusText);
    }

    throw new errors.ServerError(axiosError.response.statusText);
  }

  return Promise.reject(axiosError);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addInterceptors = (client: any) => {
  client.interceptors.request.use(null, errorRequestHandler);

  client.interceptors.response.use(null, errorResponseHandler);
};

const createAxiosClient = (options = {}) => {
  const axiosClient = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  });

  addInterceptors(axiosClient);

  return axiosClient;
};

const createMultipartAxiosClient = (options = {}) => {
  const boundary =
    "-----------------------------" +
    Math.floor(Math.random() * Math.pow(10, 8));

  const axiosClient = axios.create({
    headers: {
      "Content-Type": "multipart/form-data; boundary=" + boundary,
    },
    ...options,
  });

  addInterceptors(axiosClient);

  return axiosClient;
};

export class HTTPClientService {
  private client?: AxiosInstance;
  private albisClient?: AxiosInstance;
  private multipartClient?: AxiosInstance;

  getDefaultHttpClient() {
    this.client = this.client || (createAxiosClient() as AxiosInstance);
    return this.client;
  }

  getAlbisHttpClient() {
    this.albisClient =
      this.albisClient || (createAxiosClient() as AxiosInstance);
    return this.albisClient;
  }

  getMultipartClient() {
    this.multipartClient =
      this.multipartClient || (createMultipartAxiosClient() as AxiosInstance);
    return this.multipartClient;
  }
}
