/* eslint-disable default-param-last */
function exec(fnName = 'log', message, ...optionalParams) {
  if (typeof console === 'undefined') {
    return;
  }
  // eslint-disable-next-line no-console
  console[fnName](message, optionalParams);
}

export const log = (message, ...optionalParams) => {
  exec('log', message, optionalParams);
};

export const info = (message, ...optionalParams) => {
  exec('info', message, optionalParams);
};

export const warn = (message, ...optionalParams) => {
  exec('warn', message, optionalParams);
};

export const error = (message, ...optionalParams) => {
  exec('error', message, optionalParams);
};

export default {
  log,
  info,
  warn,
  error
};
