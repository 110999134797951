import { useEffect } from "react";
import { getCurrentLanguage } from "util/MagnoliaAppHelpers";
import { useRecoilState } from "recoil";
import { useLocation } from "react-router";
import { languageState } from "state/language";
import { pagePathState } from "state/magnolia";

const LocationLanguageHandler = () => {
  const location = useLocation();
  const [language, setLanguage] = useRecoilState(languageState);
  const [pagePath, setPagePath] = useRecoilState(pagePathState);

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();
    if (language !== currentLanguage) {
      setLanguage(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setLanguage]);

  useEffect(() => {
    if (pagePath !== location.pathname) {
      setPagePath(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setPagePath]);

  return null;
};

export default LocationLanguageHandler;
