export enum Seniority {
  student = "student",
  graduate = "graduate",
  youngprofessional = "youngprofessional",
  professional = "professional",
}

export type PositionType =
  | "Vollzeit"
  | "Teilzeit"
  | "Praktikum"
  | "Freelancer"
  | "Eigentümer"
  | "Partner"
  | "Aufsichtsratmitglied"
  | "Ehrenamt"
  | "Teilzeit / Vollzeit"
  | "Traineeship"
  | "Aushilfe"
  | "Soziales Engagement"
  | "Lehre"
  | "Ausbildung"
  | "Geringfügig"
  | "Werkstudent"
  | "Befristet / Saisonal"
  | "Duales Studium";

export enum JobCompany {
  "Otto Krahn Group" = "okg",
  MOCOM = "mocom",
  ALBIS = "albis",
  WIPAG = "wipag",
  KRAHN = "krahn",
  "Krahn Ceramics" = "krahnCeramics",
  "OKG New Business" = "okNewBusiness",
  "OKG CorFu" = "okCorfu",
}

export enum ContractType {
  temporary = "Befristet",
  permanent = "Unbefristet",
}

export interface OnlyfyJobResponse {
  title: string;
  department_id: number;
  zip_code?: number;
  position_type: PositionType;
  start_of_work: Date;
  short_handle: string;
  industry_id: number;
  custom_fields: {
    custom_field_id: number;
    values: { value: string }[];
    name: string;
  }[];
  job_contents?: { content: string }[];
  apply_url?: string;
  instance: { name: string };
  has_immediate_start_of_work: boolean;
  is_published_on_job_center: boolean;
  placeholder: { city?: string };
}

export interface OnlyfyJob {
  title: string;
  department: number;
  seniority: Seniority;
  positionType: PositionType;
  startOfWork?: Date;
  hasImmediateStartOfWork?: boolean;
  shortHandle: string;
  contractType?: ContractType;
  company: JobCompany;
  jobContents: { content: string }[];
  applyUrl?: string;
  location?: string;
  websitecat?: string;
  websiteLanguage?: string;
}

export const getOnlyfyJobFromResponse = (
  resJob: OnlyfyJobResponse
): OnlyfyJob => {
  const jobBoardPublishDate = resJob.custom_fields.find(
    (field) => field.name === "jobboardPublishDate"
  )?.values[0].value;

  const websiteCat = resJob.custom_fields.find(
    (field) => field.name === "websiteCategory"
  )?.values[0].value;

  const websiteLanguage = resJob.custom_fields.find(
    (field) => field.name === "websiteLanguage"
  )?.values[0].value;

  const job = {
    websiteCat: websiteCat,
    title: resJob.title.trim(),
    department: resJob.department_id,
    seniority: getSeniority(websiteCat),
    positionType: resJob.custom_fields.find(
      (field) => field.custom_field_id === 353184
    )?.values[0].value as PositionType,
    contractType: resJob.custom_fields.find(
      (field) => field.custom_field_id === 353189
    )?.values[0].value as ContractType,
    startOfWork: resJob.has_immediate_start_of_work
      ? new Date()
      : resJob.start_of_work
      ? new Date(resJob.start_of_work)
      : jobBoardPublishDate
      ? new Date(jobBoardPublishDate)
      : new Date(),
    hasImmediateStartOfWork: resJob.has_immediate_start_of_work,
    shortHandle: resJob.short_handle,
    company: resJob.instance?.name
      ? JobCompany[resJob.instance.name as keyof typeof JobCompany]
      : JobCompany["Otto Krahn Group"],
    jobContents:
      resJob.job_contents?.map((content) => ({
        content: content.content
          .replaceAll(/<[b,i]>]*>/g, "")
          .replaceAll("&nbsp;", " "),
      })) ?? [],
    applyUrl: resJob.apply_url,
    location: resJob.placeholder.city,
    websiteLanguage,
  };

  return job;
};

const getSeniority = (value?: string) => {
  if (value?.toLowerCase().includes("student")) {
    return Seniority.student;
  } else if (value?.toLowerCase().includes("professional")) {
    return Seniority.professional;
  } else if (value?.toLowerCase().includes("jobstarter")) {
    return Seniority.youngprofessional;
  } else {
    return Seniority.graduate;
  }
};
